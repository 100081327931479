import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useNavigate} from "react-router-dom";
import {Employee} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";


export const EnableEmployeeModal: FC<{employee: Employee}> = (props) => {
  const {updateEmployee} = useApi()
  const navigate = useNavigate()
  const confirm = async () => {
    await updateEmployee({...props.employee, enabled: true})
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om <strong>{props.employee.firstName} {props.employee.lastName}</strong> op acief te zetten.
      Dit betekent dat hij/zij ingepland kan worden. Je kunt deze medewerker ook aan een team toewijzen.
    </p>
    <ModalFooter icon={fa.faPlus} text={"Naar actief"} onSubmit={confirm} />
  </>
}
