import React, {FC, useEffect, useMemo, useState} from "react";
import {faChevronLeft, faChevronRight, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {AddGlobalHolidayModal} from "../modals/AddGlobalHolidayModal";
import {useApi} from "../api/APIContext";
import {dateToString} from "../planning/util/datetime";
import {Employee, Holiday, PlanningAbsence} from "../api/dto";
import {DeleteGlobalHolidayModal} from "../modals/DeleteGlobalHolidayModal";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TinyEmployeeAvatar} from "../personel/components/EmployeeAvatar";
import {useModal} from "@traas/lib/src/components/layout/ModalProvider";
import {Button, IconButton} from "@traas/lib/src/components/form/Button";
import {Card} from "@traas/lib/src/components/Card";
import {PillSelect} from "@traas/lib/src/components/form/PillSelect";

export const Holidays: FC = () => {
  const [selectedMonth, setSelectedMonth] = React.useState(new Date())
  const addHolidayModal = useModal({'title': 'Nieuwe algemene vrije dag', body: <AddGlobalHolidayModal />})
  const [absenceDays, setAbsenceDays] = useState<PlanningAbsence[]>([])

  const {holidays, employees, getPlanningAbsence} = useApi()

  useEffect(() => {
    setAbsenceDays([])
    getPlanningAbsence(selectedMonth).then((res) => {
      setAbsenceDays(res)
    })
  }, [selectedMonth]);

  const years = Array.from(new Set(holidays.map(h => h.date.getFullYear().toString())))
  const currentYear = new Date().getFullYear().toString()
  const [holidayYearFilter, setHolidayYearFilter] = useState<string>(years.includes(currentYear) ? currentYear : years[years.length - 1])
  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-lg mx-auto"}>
          <div className={"flex justify-between items-center h-12 mb-4"}>
            <h1 className={"flex items-center text-2xl font-bold"}>Vrije dagen</h1>
            <Button icon={faPlus} text={'Nieuwe algemene vrije dag'} type={'primary'} size={'md'} onClick={() => addHolidayModal.open() }/>
          </div>
          <HolidayCalendar
            holidays={holidays}
            absenceDays={absenceDays}
            month={selectedMonth}
            employees={employees}
            prevMonth={() => setSelectedMonth(d => moment(d).subtract(1, 'month').toDate())}
            nextMonth={() => setSelectedMonth(d => moment(d).add(1, 'month').toDate())}
            reset={() => setSelectedMonth(new Date())}
          />
          <Card title={'Vrije dagen'}>
            <PillSelect options={Object.fromEntries(years.map(y => [y,y]))} value={holidayYearFilter} onChange={setHolidayYearFilter} />
            <div className={"border border-slate-200 rounded -mx-1"}>
              {holidays.length === 0 && <div className={"flex flex-col justify-center items-center px-3 py-8"}>
                  <div className={"mb-4"}>Er zijn nog geen algemene vrije dagen</div>
                  <Button icon={faPlus} text={'Nieuwe algemene vrije dag'} type={'secondary'} size={'md'} onClick={() => addHolidayModal.open() }/>
              </div>}
              {holidays.filter(h => h.date.getFullYear().toString() === holidayYearFilter).sort((a,b) => a.date.getTime() > b.date.getTime() ? 1 : -1).map((h, i) => <div key={i} className={`${i > 0 ? 'border-t border-slate-200' : ''} flex items-center px-3 py-2`} >
                <div className={"flex-1"}>
                  <div className="font-medium text-slate-900">{h.name}</div>
                  <div className={"text-sm font-medium text-slate-700"}>{dateToString(h.date)} {h.date.getFullYear()}</div>
                </div>
                <div className={'mr-2'}>
                  { h.type === 'company_wide_vacation' && "Collectieve vrije dag" }
                  { h.type === 'national_holiday' && "Feestdag" }
                </div>
                <HolidayControls holiday={h} />
              </div>)}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
}

const HolidayControls: FC<{holiday:Holiday}> = ({holiday}) => {
  const removeHolidayModal = useModal({title: "Verwijderen", body: <DeleteGlobalHolidayModal holiday={holiday} />})
  return <IconButton icon={faTimes} type={"danger"} size={'md'} onClick={() => removeHolidayModal.open()} />
}



const HolidayCalendar: FC<{holidays: Holiday[], absenceDays: PlanningAbsence[], employees: Employee[], month: Date, nextMonth: () => void, prevMonth: () => void, reset: () => void}> = (props) => {

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        props.prevMonth()
      } else if (e.key === 'ArrowRight') {
        props.nextMonth()
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, []);

  const options = useMemo(() => {
    const options: Date[] = []
    const firstDay = new Date(props.month.getFullYear(), props.month.getMonth(), 1)
    const lastDay = new Date(props.month.getFullYear(), props.month.getMonth() + 1, 0)

    for (let i = -firstDay.getDay()+1; i <= lastDay.getDate() - 1; i++) {
      options.push(moment(firstDay).add(i, 'day').toDate())
    }
    const nWeeks = Math.ceil(options.length / 7)
    const lastOption = options[options.length - 1]
    const extraDays = 7 * nWeeks - options.length
    for (let i = 0; i < extraDays; i++) {
      options.push(moment(lastOption).add((i + 1), 'day').toDate())
    }
    return options
  }, [props.month])


  return <div className={"bg-white rounded flex flex-col"}>
    <div className={"px-4 py-3 border-b border-slate-100 flex items-center h-14"}>
      <button className={"h-8 w-8 flex items-center justify-center text-slate-700 hover:bg-slate-100 rounded-full"} onClick={props.prevMonth}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className={"w-40 font-bold text-center"}>
        {moment(props.month).format('MMMM YYYY')}
      </span>
      <button className={"h-8 w-8 flex items-center justify-center text-slate-700 hover:bg-slate-100 rounded-full"} onClick={props.nextMonth}>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      {(props.month.getMonth() !== new Date().getMonth() || props.month.getFullYear() !== new Date().getFullYear()) && <Button type={'secondary'} size={'md'} text={'Terug deze maand'} onClick={props.reset} />}
    </div>
    <div className={"grid grid-cols-7 mt-2"}>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Ma</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Di</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Wo</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Do</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Vr</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Za</div>
      <div className={"text-xs px-2 py-1 uppercase tracking-wide text-slate-500"}>Zo</div>
    </div>
    <div className={"grid grid-cols-7 mt-2 border-l border-t border-slate-200"}>
      {options.map((option, index) => {
        if (option.getMonth() !== props.month.getMonth()) {
          return <div key={index} className={"border-r border-b min-h-[80px] text-xs p-2 border-slate-200 bg-slate-100"}>
            <div className={"opacity-25 flex justify-between"}>
              <div className={"h-5 flex items-center justify-center font-light text-slate-900"}>{option.getDay() === 1 ? `${moment(option).week()}` : ''}</div>
              <div className={`w-5 h-5 flex items-center justify-center rounded-full`}>{option.getDate()}</div>
            </div>
          </div>
        }
        const isToday = option.toDateString() === new Date().toDateString()
        const disabled = option.getDay() === 6 || option.getDay() === 0 // za en zo
        const date = moment(option).format('YYYY-MM-DD')
        const absenceDays = props.absenceDays.filter(a => a.day === date)
        const absenceVacationDays = absenceDays.filter(a => a.reason === 'holiday')
        const absenceSickDays = absenceDays.filter(a => a.reason === 'ill')
        const holidays = props.holidays.filter(h => h.date.toDateString() === option.toDateString())
        const employeeMap = props.employees.reduce((acc, e) => {
          acc[e.id] = e
          return acc
        }, {} as {[key: string]: Employee})

        return <div key={index} className={`border-r border-b min-h-[100px] text-xs p-2 border-slate-200 ${disabled ? 'bg-slate-100' : ''}`}>
          <div className={"flex justify-between"}>
            <div className={"h-5 flex items-center justify-center font-light text-slate-400"}>{option.getDay() === 1 ? `${moment(option).week()}` : ''}</div>
            <div className={`w-5 h-5 flex items-center justify-center rounded-full font-medium ${isToday ? 'bg-red-600 text-white' : ''}`}>{option.getDate()}</div>
          </div>
          <div className={"flex flex-col space-y-1"}>
            {holidays.map((h, i) => <div key={i} className={"rounded-sm bg-blue-600 text-white px-2 overflow-ellipsis whitespace-nowrap"}>{h.name}</div>)}
            {absenceVacationDays.length > 0 && <div className={"border border-slate-200 mt-2 p-1 rounded"}>
              <h3 className={"text-xs uppercase text-slate-600 font-medium"}>Op vakantie</h3>
              <div className={"flex flex-wrap gap-1 mt-1"}>
                {absenceVacationDays.map((a, i) => {
                  const employee = employeeMap[a.employeeId]
                  return <TinyEmployeeAvatar employee={employee} key={i} />
                })}
              </div>
            </div>}
            {absenceSickDays.length > 0 && <div className={"border border-slate-200 mt-2 p-1 rounded"}>
              <h3 className={"text-xs uppercase text-slate-600 font-medium"}>Ziek gemeld</h3>
              <div className={"flex flex-wrap gap-1 mt-1"}>
                {absenceSickDays.map((a, i) => {
                  const employee = employeeMap[a.employeeId]
                  return <TinyEmployeeAvatar employee={employee} key={i} />
                })}
              </div>
            </div>}
          </div>
        </div>
      })}
    </div>
  </div>
}
