import React from "react";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {UpcomingChanges} from "./UpcomingChanges";
import {TeamOverview} from "./TeamOverview";
import {AddTeamModal} from "../../modals/AddTeamModal";
import {useModal} from "@traas/lib/src/components/layout/ModalProvider";
import {Button} from "@traas/lib/src/components/form/Button";

export const TeamManager = () => {
  const addTeamModal = useModal({title: 'Nieuw team', body: <AddTeamModal/>})
  return <div className={"-mx-6 -my-4 flex items-stretch"}>
    <div className={"flex-1 py-4 px-6"}>
      <div className={"flex items-start justify-start"}>
        <h2 className={"font-medium text-sm text-slate-600 h-8 mb-2 flex-1"}>Teams</h2>
        <Button type={'primary'} size={'sm'} text={'Nieuw team'} icon={faAdd} onClick={() => addTeamModal.open()}/>
      </div>
      <TeamOverview/>
    </div>
    <div className={"w-1/2 lg:w-1/3 border-l py-4 px-6 border-slate-200"}>
      <h2 className={"font-medium text-sm text-slate-600 h-8 mb-2"}>Aankomende wijzigingen</h2>
      <UpcomingChanges/>
    </div>
  </div>
}

