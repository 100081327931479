import {usePlanner} from "../PlannerContext";
import {useEffect, useRef} from "react";


export const FocussedTaskDialog = () => {
  const {clearFocussedTask} = usePlanner()
  const dialogRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setTimeout(() => {
      dialogRef.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }, 100)
    setTimeout(() => {
      clearFocussedTask()
    }, 5000)
  }, []);
  return <>
    <div className={'fixed h-full w-full top-0 left-0 z-20'} onClick={() => clearFocussedTask()}>
    </div>
    <div ref={dialogRef} className={'absolute -top-20 -left-16 h-16 w-40 bg-white border-2 border-blue-800 rounded-lg shadow-2xl text-sm text-slate-800 flex flex-col justify-center items-center z-30'}>
      <div className={'font-medium text-black mt-1'}>Hier is de taak.</div>
      <button className={'font-medium text-blue-700 hover:bg-blue-100 py-1 px-2 rounded'} onClick={() => clearFocussedTask()}>Begrepen</button>
    </div>
    <div className={'absolute -top-6 left-2 h-4 w-4 rotate-45 bg-white border-r-2 border-b-2 border-blue-800 z-30'}></div>
  </>
}