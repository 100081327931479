import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Order} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";


export const DeleteOrderModal: FC<{order: Order}> = (props) => {
  const {deleteOrder} = useApi()
  const confirm = async () => {
    await deleteOrder(props.order.id)
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om de bestelregel <strong>{props.order.text}</strong> te verwijderen.
    </p>
    <ModalFooter icon={fa.faTimes} text={"Verwijderen"} onSubmit={confirm} />
  </>
}
