import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";
import {Color, Employee} from "../api/dto";
import {Toggle} from "@traas/lib/src/components/form/Toggle";


export const EditEmployeeContractModal: FC<{employee: Employee}> = ({employee}) => {
  const {updateEmployeeMetadata} = useApi()
  const [from, setFrom] = React.useState<Date>(employee.metadata.contractStartDate ?? new Date())
  const [to, setTo] = React.useState<Date|null>(employee.metadata.contractEndDate)
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await updateEmployeeMetadata(employee.id, undefined, undefined, from, to)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <Input label={'Startdatum'} value={from} focus={true} onChange={setFrom} type={'date'} />
    <Toggle value={to !== null} onChange={(v) => setTo(v ? new Date() : null)} label={'Medewerker heeft einddatum'} />
    {to !== null && <Input label={'Einddatum'} value={to} onChange={setTo} type={'date'} />}
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faSave} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
