import React, {FC, useEffect, useMemo, useState} from "react";
import {useApi} from "../api/APIContext";
import {useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {Task, TaskSupplier} from "../api/dto";
import {
  faCancel, faCheck, faCircleNotch, faNoteSticky,
  faPencil,
  faPlus, faSave, faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {Button, IconButton} from "@traas/lib/src/components/form/Button";
import {Select} from "@traas/lib/src/components/form/Select";
import {Input} from "@traas/lib/src/components/form/Input";
import {useThemes} from "../planning/util/theming";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTaskTypes} from "../planning/hooks/useTaskTypes";
import moment from "moment/moment";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export const TaskDetailsModal: FC<{task: Task}> = (p) => {
  const { close } = useModalControls()
  const {tasks, projects} = useApi()
  const {getTheme} = useThemes()

  const task = useMemo(() => {
    return tasks.find(t => t.id === p.task.id)!
  }, [tasks, p.task])

  const project = useMemo(() => {
    return projects.find(project => project.id === p.task.projectId)
  }, [projects, p.task])

  const {getTaskColor} = useTaskTypes()
  const {bg} = getTheme(getTaskColor(task) ?? undefined, task.isInvoiced)

  return <div className={"space-y-2"}>
    <div className={""}>
      <div className={`${bg} text-white text-sm px-4 py-1 rounded-lg mb-4`}>
        <div>{task.description}</div>
        {project &&
          <div className={"text-xs opacity-75"}>#{project.projectNumber}, {project.deliveryDetails.city}</div>}
      </div>
      <h2 className={"mt-4 mb-1"}>Afhankelijkheden (Leveranciers)</h2>
      <SupplierManager task={task}/>
      <h2 className={"mt-4"}>Werkbon opmerkingen voor medewerkers</h2>
      <div className={"grid grid-cols-1 lg:grid-cols-2 gap-3"}>
        <WorkorderRemarkEditor task={task}/>
        <ProjectRemarkEditor task={task}/>
      </div>
      <h2 className={"mt-4"}>Acties vereist</h2>
      <TodoEditor task={task}/>
    </div>
    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'secondary'} size={'md'} text={'Sluiten'} onClick={() => close()}/>
    </footer>
  </div>
}

const SupplierManager: FC<{task: Task}> = ({task}) => {
  const cellStyle = 'px-2 py-2 border-b border-slate-200 whitespace-nowrap'
  const {suppliers, addTaskSupplier} = useApi()
  // const suppliers: Supplier[] =    []
  const [isEditing, setIsEditing] = useState(false)
  const [selectedSupplier, setSelectedSupplier] = useState<string>(suppliers[0]?.id??'')
  const [description, setDescription] = useState<string>('')
  const supplierOptions = Object.fromEntries(suppliers.map(s => [s.id, s.name]))

  const submit = async () => {
    await addTaskSupplier(task.id, selectedSupplier, description, 'red')
    setIsEditing(false)
  }

  useEffect(() => {
    if (!isEditing && description.length > 0) {
      setDescription('')
    }
  }, [isEditing, description]);

  if (task.suppliers.length === 0 && !isEditing) {
    return <div className={"border border-slate-200 rounded-lg p-4 flex flex-col items-center justify-center text-sm space-y-2"}>
      <p>Deze taak heeft geen afhankelijkheden.</p>
      <Button type={'secondary'} size={"xs"} text={'Afhankelijkheid toevoegen'} icon={faPlus} onClick={() => setIsEditing(true)} />
    </div>
  }

  return <div className={"border border-slate-200 rounded-lg"}>
    <table className={"w-full max-w-full"}>
      <tbody>
        {task.suppliers.map((supplier, i) => {
          const details = suppliers.find(s => s.id === supplier.supplierId)
          return <tr key={i}>
            <td className={cellStyle + " w-full text-sm font-medium pr-4"}>{details?.name}</td>
            <td className={cellStyle + " text-sm overflow-hidden"}><DescriptionEditor task={task} taskSupplier={supplier} /></td>
            <td className={cellStyle}>
              <TrafficLight s={supplier} task={task} />
            </td>
          </tr>
        })}
        {isEditing ? <>
          <tr>
            <td className={cellStyle}>
              <Select label={'Leverancier'} options={supplierOptions} value={selectedSupplier} onChange={setSelectedSupplier} />
            </td>
            <td className={cellStyle}>
              <Input type={'text'} label={'Omschrijving (optioneel)'} value={description} onChange={setDescription} />
            </td>
            <td className={cellStyle + ' w-20'}>
              <div className={"flex mt-5 space-x-1"}>
                <IconButton type={'primary'} size={'sm'} icon={faPlus} onClick={submit} />
                <IconButton type={'danger'} size={'sm'} icon={faCancel} onClick={() => setIsEditing(false)} />
              </div>
            </td>
          </tr>
        </> : <>
          <tr>
            <td className={'px-2 py-2'} colSpan={3}>
              <Button type={'secondary'} size={"xs"} text={'Afhankelijkheid toevoegen'} icon={faPlus} onClick={() => setIsEditing(true)} />
            </td>
          </tr>
        </>}
      </tbody>
    </table>
  </div>
}

const TrafficLight: FC<{s: TaskSupplier, task: Task}> = props => {
  const {updateTaskSupplierStatus} = useApi()
  const [status, setStatus] = useState(props.s.status)
  useEffect(() => {
    setStatus(props.s.status)
  }, [props.s.status]);


  const updateStatus = async (s: "red"|"orange"|"green") => {
    setStatus(s)
    await updateTaskSupplierStatus(props.task.id, props.s.id, s)
  }

  return <div className={`rounded-full p-1 flex space-x-1`}>
    <button onClick={() => updateStatus('red')} className={`w-4 h-4 rounded-full border-2 ${status === "red" ? 'bg-red-600 border-red-600' : 'bg-transparent border-red-400'}`}></button>
    <button onClick={() => updateStatus('orange')} className={`w-4 h-4 rounded-full border-2 ${status === "orange" ? 'bg-amber-600 border-amber-600' : 'bg-transparent border-amber-400'}`}></button>
    <button onClick={() => updateStatus('green')} className={`w-4 h-4 rounded-full border-2 ${status === "green" ? 'bg-green-600 border-green-600' : 'bg-transparent border-green-400'}`}></button>
  </div>
}

const DescriptionEditor: FC<{taskSupplier: TaskSupplier, task: Task}> = ({task, taskSupplier}) => {
  const {updateTaskSupplierDescription, deleteTaskSupplier} = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const [description, setDescription] = useState(taskSupplier.description ?? '')
  useEffect(() => {
    if (!isEditing) {
      setDescription(taskSupplier.description ?? '')
    }
  }, [taskSupplier, isEditing]);

  const save = async () => {
    await updateTaskSupplierDescription(task.id, taskSupplier.id, taskSupplier.status, description)
    setIsEditing(false)
  }
  const del = async () => {
    await deleteTaskSupplier(task.id, taskSupplier.id)
    setIsEditing(false)
  }

  if (isEditing) {
    return <div className={"h-8 flex justify-start items-center group"}>
      <input type={'text'} className={"-mx-1 p-1 h-8 border border-slate-300 rounded flex-1 mr-2"} autoFocus={true} value={description} onChange={(e) => setDescription(e.target.value)} />
      <IconButton type={'primary'} size={'xs'} icon={faSave} onClick={save} />
      <IconButton type={'secondary'} size={'xs'} icon={faCancel} onClick={() => setIsEditing(false)} />
      <IconButton type={'danger'} size={'xs'} icon={faTrash} onClick={del} />
    </div>
  }

  return <div className={"h-8 flex justify-start items-center group"}>
    <span className={"whitespace-normal"}>{description}</span>
    <button className={'ml-2'}>
      <FontAwesomeIcon icon={faPencil} className={"hover:bg-slate-200 group-hover:text-slate-700 rounded-sm w-3 h-3 p-2"} onClick={() => setIsEditing(true)} />
    </button>
  </div>
}
const WorkorderRemarkEditor: FC<{task: Task}> = ({task}) => {
  const {updateTaskMetadata} = useApi()
  return <NoteEditor label={'Opmerkingen op Werkbon'} text={task.workorderRemark} saveText={async (newText) => {
    await updateTaskMetadata(task.id, {workorderRemark: newText})
  }} />
}
const ProjectRemarkEditor: FC<{task: Task}> = ({task}) => {
  const {updateTaskMetadata} = useApi()
  return <NoteEditor label={'Opmerkingen op Project'} text={task.projectRemark} saveText={async (newText) => {
    await updateTaskMetadata(task.id, {projectRemark: newText})
  }} />
}

const NoteEditor: FC<{text?: string|null, saveText: (newText: string) => Promise<void>, label: string}> = props => {
  const [mode, setMode] = useState<"view"|"edit">("view")
  const [viewText, setViewText] = useState(props.text ?? '')
  const [text, setText] = useState(props.text ?? '')
  const edit = () => {
    setText(props.text ?? '')
    setMode("edit")
  }
  const save = async () => {
    await props.saveText(text)
    setViewText(text)
    setMode("view")
  }
  useEffect(() => {
    setViewText(props.text ?? '')
  }, [props.text]);
  return <div className={"border border-slate-200 rounded p-2 my-2"}>
    <div className={"flex items-center justify-between"}>
      <span className={"flex items-center text-xs font-bold text-blue-900"}>
        <FontAwesomeIcon icon={faNoteSticky} className={"text-blue-900 text-xs mr-2"} />
        {props.label}
      </span>
    </div>
    {mode === "view" && <button className={"text-xs text-slate-600 mt-2 p-2 rounded flex w-full hover:bg-slate-50"} onClick={() => edit()}>
      <pre className={'flex-1 text-left font-sans whitespace-pre-wrap'}>{viewText}</pre>
      <FontAwesomeIcon icon={faPencil} className={"text-slate-600 text-xs mr-2"} />
    </button>}
    {mode === "edit" && <div>
      <textarea className={"outline-none bg-slate-100 rounded mt-2 mb-1 p-2 text-sm w-full"} value={text} onChange={(e) => setText(e.target.value)} />
      <div className={"flex space-x-2"}>
        <Button type={'primary'} size={'xs'} text={'Opslaan'} onClick={() => save()} />
        <Button type={'secondary'} size={'xs'} text={'Annuleren'} onClick={() => setMode("view")} />
      </div>
    </div>}
  </div>
}

const TodoEditor: FC<{task: Task}> = ({task}) => {
  const {updateTaskMetadata} = useApi()
  return <TodosEditor todos={task.todos} saveTodos={async (newTodos) => {
    await updateTaskMetadata(task.id, {todos: newTodos})
  }} />
}
const TodosEditor: FC<{todos: {[text: string]: boolean}, saveTodos: (newTodos: {[text: string]: boolean}) => Promise<void>}> = ({todos, saveTodos}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [description, setDescription] = useState('')
  const submit = async () => {
    const newTodos = {...todos}
    newTodos[description] = false
    await saveTodos(newTodos)
    setDescription('')
    setIsEditing(false)
  }
  const check = async (item: string) => {
    const newTodos = {...todos}
    newTodos[item] = !todos[item]
    await saveTodos(newTodos)
  }
  const del = async (item: string) => {
    const newTodos = {...todos}
    delete newTodos[item]
    await saveTodos(newTodos)
  }
  return <div className={"text-sm border border-slate-200 rounded mt-2 px-2"}>
    {Object.entries(todos).map(([text, value], i) => {
      return <TodoRow key={i} text={text} value={value} toggle={async () => check(text)} del={() => del(text)} />
    })}
    {Object.entries(todos).length === 0 && <div className={"text-sm text-slate-600 text-center py-4"}>
      Geen acties vereist.
    </div>}
    {isEditing ? <div className={"flex items-center"}>
      <div className={"pb-1 mr-2 flex-1"}>
        <Input type={'text'} label={''} value={description} onChange={setDescription} />
      </div>
      <div className={"flex space-x-1"}>
        <IconButton type={'primary'} size={'sm'} icon={faPlus} onClick={submit} />
        <IconButton type={'danger'} size={'sm'} icon={faCancel} onClick={() => setIsEditing(false)} />
      </div>
    </div> : <>
      <div className={'px-2 py-2'}>
        <Button type={'secondary'} size={"xs"} text={'Vereiste actie toevoegen'} icon={faPlus} onClick={() => setIsEditing(true)} />
      </div>
    </>}
  </div>
}
const TodoRow: FC<{text: string, value: boolean, toggle: () => Promise<void>, del: () => Promise<void>}> = props => {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const del = async () => {
    setDeleting(true)
    await props.del()
    setDeleting(false)
  }
  const submit = async () => {
    setLoading(true)
    await props.toggle()
    setLoading(false)
  }
  return <div className={"border-b border-slate-200 cursor-pointer hover:bg-slate-50 -mx-2 flex items-stretch group"}>
    <button className={"flex items-center flex-1 px-3 py-2"} disabled={loading} onClick={submit}>
      <div className={`h-6 w-6 flex items-center justify-center rounded-full mr-3 border border-slate-200 ${props.value ? 'bg-blue-900 text-white border-blue-900' : `border-slate-200 ${loading ? 'text-blue-900' : 'text-transparent group-hover:text-slate-400'}`}`}>
        <FontAwesomeIcon icon={loading ? faCircleNotch : faCheck} spin={loading} />
      </div>
      {props.text}
    </button>
    <div className={"flex items-center justify-center"}>
      <button disabled={deleting} className={'text-red-600 h-6 w-6 hover:bg-red-100 mx-2 rounded '} onClick={del}><FontAwesomeIcon icon={deleting ? faCircleNotch : faTimes} spin={deleting} /></button>
    </div>
  </div>
}