import React, {FC, useEffect, useMemo, useState} from "react";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Employee} from "../api/dto";
import {faCancel, faHouse, faSave, faShuffle, faThermometer,} from "@fortawesome/free-solid-svg-icons";
import {Select} from "@traas/lib/src/components/form/Select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EnrichedTeamDayAvailability} from "../planning/components/WeekdayPlanner";
import {EmployeeAvatar} from "../personel/components/EmployeeAvatar";
import {ValidationError} from "../api/api";
import moment from "moment";
import {Input} from "@traas/lib/src/components/form/Input";
import {Button} from "@traas/lib/src/components/form/Button";

export const MoveEmployeeModal: FC<{date: Date, employee: Employee, currentTeam: EnrichedTeamDayAvailability , teams: EnrichedTeamDayAvailability[]}> = (p) => {
  const [mode, setMode] = useState<"move"|"vacation"|"sick">("move")

  return <div className={"space-y-2"}>
    <div className={"border border-slate-200 rounded flex items-center mb-4"}>
      <EmployeeAvatar employee={p.employee} />
      <div>
        <div className={"font-medium text-blue-900"}>{p.employee.firstName} {p.employee.lastName}</div>
        <div className={"text-sm text-slate-800"}>{p.currentTeam.team.name}</div>
      </div>
    </div>
    <TemporaryChanges date={p.date} employee={p.employee} />
    <div className={"flex items-stretch h-24 space-x-4 mb-4"}>
      <button className={`flex-1 border rounded ${mode === "move" ? "border-blue-800 text-blue-800 bg-blue-50" : "text-slate-500 border-slate-200"} flex flex-col items-center justify-center`} onClick={() => setMode('move')}>
        <FontAwesomeIcon icon={faShuffle} className={"text-2xl"}/>
        <span className={"text-xs mt-2"}>Verplaatsen</span>
      </button>
      <button className={`flex-1 border rounded ${mode === "vacation" ? "border-blue-800 text-blue-800 bg-blue-50" : "text-slate-500 border-slate-200"} flex flex-col items-center justify-center`} onClick={() => setMode('vacation')}>
        <FontAwesomeIcon icon={faHouse} className={"text-2xl"}/>
        <span className={"text-xs mt-2"}>Verlof</span>
      </button>
      <button className={`flex-1 border rounded ${mode === "sick" ? "border-blue-800 text-blue-800 bg-blue-50" : "text-slate-500 border-slate-200"} flex flex-col items-center justify-center`} onClick={() => setMode('sick')}>
        <FontAwesomeIcon icon={faThermometer} className={"text-2xl"}/>
        <span className={"text-xs mt-2"}>Ziek</span>
      </button>
    </div>
    {mode === "move" && <MoveEmployee date={p.date} employee={p.employee} currentTeam={p.currentTeam} teams={p.teams} />}
    {mode !== "move" && <SickOrVacation date={p.date} employee={p.employee} currentTeam={p.currentTeam} teams={p.teams} reason={mode === "sick" ? "ill" : "holiday"} />}
  </div>
}

const MoveEmployee: FC<{date: Date, employee: Employee, teams: EnrichedTeamDayAvailability[], currentTeam: EnrichedTeamDayAvailability}> = (props) => {
  const [startDate, setStartDate] = useState<Date>(props.date)
  const [endDate, setEndDate] = useState<Date>(props.date)
  const teamOptions = props.teams
    .filter(t => t.teamId !== props.currentTeam.teamId && t.teamId !== 'absence')
    .reduce((acc, team) => {
      acc[team.teamId] = team.team.name
      return acc
    }, {} as {[key: string]: string})
  const [team, setTeam] = useState<string>(Object.keys(teamOptions)[0])
  const [isPermanent, setIsPermanent] = useState(false)
  const [wholeDay, setWholeDay] = useState(true)

  const [errors, setErrors] = useState<string[]>([])

  const {joinTeam} = useApi()

  // If the end date is less than an hour after the start date, or even before it, move it to exactly one hour after the start date.
  useEffect(() => {
    if (moment(endDate).diff(startDate, 'hours') < 1) {
      setEndDate(moment(startDate).add(1, 'hour').toDate())
    }
  }, [startDate, endDate]);

  const submit = async () => {
    const selectedTeam = props.teams.find(t => t.teamId === team)
    if (!selectedTeam) {
      setErrors(["Geen team beschikbaar"])
      return
    }
    const newStartDate = wholeDay ? moment(props.date).set({hours: 2, minutes: 0}).toDate() : startDate
    const newEndDate = isPermanent ? undefined : (wholeDay ? moment(props.date).set({hours: 22, minutes: 0}).toDate() : endDate)
    await joinTeam(selectedTeam.team, props.employee, newStartDate, !isPermanent, undefined, newEndDate)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div>
    <div className={""}>
      <label
        className={`flex items-center px-4 mt-4 rounded h-12 border cursor-pointer ${wholeDay ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
        <input type="checkbox" checked={wholeDay} onChange={() => setWholeDay(x => !x)}/>
        <span className={"ml-2 font-medium text-sm"}>Hele dag</span>
      </label>
      {!wholeDay && <Input type={"time"} label={"Starttijd"} value={startDate} onChange={(newDate) => {
        const newDateWithDate = moment(newDate).set({
          year: props.date.getFullYear(),
          month: props.date.getMonth(),
          date: props.date.getDate(),
        }).toDate()
        setStartDate(newDateWithDate)
      }}/>}
    </div>
    {!isPermanent && !wholeDay && <>
      <div className={"mt-3"}>
        <Input type={"date"} label={"Einddatum"} value={endDate} onChange={(newDate) => {
          const newDateWithTime = moment(newDate).set({
            hours: endDate.getHours(),
            minutes: endDate.getMinutes(),
          }).toDate()
          setEndDate(newDateWithTime)
        }}/>
      </div>
      <div className={"mt-3"}>
        <Input type={"time"} label={"Eindtijd"} value={endDate} onChange={(newDate) => {
          const newDateWithDate = moment(newDate).set({
            year: endDate.getFullYear(),
            month: endDate.getMonth(),
            date: endDate.getDate(),
          }).toDate()
          setEndDate(newDateWithDate)
        }}/>
      </div>
    </>}
    <div className={"mt-3"}>
      <Select label={"Team"} options={teamOptions} value={team} onChange={setTeam}/>
    </div>
    <label
      className={`flex items-center px-4 mt-4 rounded h-12 border cursor-pointer ${isPermanent ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
      <input type="checkbox" checked={isPermanent} onChange={() => setIsPermanent(x => !x)}/>
      <span className={"ml-2 font-medium text-sm"}>Wijziging doorlopend toepassen</span>
    </label>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter text={'Verplaatsen'} icon={faShuffle} onSubmit={() => submit()}/>
  </div>
}
const SickOrVacation: FC<{
  date: Date,
  employee: Employee,
  teams: EnrichedTeamDayAvailability[],
  currentTeam: EnrichedTeamDayAvailability,
  reason: "ill" | "holiday"
}> = (props) => {

  const [wholeDay, setWholeDay] = useState(true)
  const [multipleDays, setMultipleDays] = useState(false)
  const [paidVacation, setPaidVacation] = useState(true)
  const [startDate, setStartDate] = useState<Date>(moment(props.date).set({hours: 2, minutes: 0}).toDate())
  const [endDate, setEndDate] = useState<Date>(moment(props.date).add(1, 'day').set({hours: 22, minutes: 0}).toDate())

  const [errors, setErrors] = useState<string[]>([])

  const {joinTeam} = useApi()

  // If the end date is less than an hour after the start date, or even before it, move it to exactly one hour after the start date.
  useEffect(() => {
    if (moment(endDate).diff(startDate, 'hours') < 1) {
      setEndDate(moment(startDate).add(1, 'hour').toDate())
    }
  }, [startDate, endDate]);

  const submit = async () => {
    await joinTeam(
      {...props.currentTeam.team, id: "absence"},
      props.employee,
      moment(startDate).set(wholeDay ? {hours: 2, minutes: 0} : {hours: startDate.getHours(), minutes: startDate.getMinutes()}).toDate(),
      true,
      props.reason,
      moment(multipleDays ? endDate : props.date).set(wholeDay ? {hours: 22, minutes: 0} : {hours: endDate.getHours(), minutes: endDate.getMinutes()}).toDate(),
      paidVacation
    )
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div>
    <hr className={'mt-4 -mx-4'}/>
    <div className={"flex space-x-4"}>
      <label
        className={`flex-1 flex items-center px-4 mt-4 rounded h-12 mb-3 border cursor-pointer ${wholeDay ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
        <input type="checkbox" checked={wholeDay} onChange={() => setWholeDay(x => !x)}/>
        <span className={"ml-2 font-medium text-sm"}>Hele dag(en)</span>
      </label>
      <label
        className={`flex-1 flex items-center px-4 mt-4 rounded h-12 mb-3 border cursor-pointer ${multipleDays ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
        <input type="checkbox" checked={multipleDays} onChange={() => setMultipleDays(x => !x)}/>
        <span className={"ml-2 font-medium text-sm"}>Meer dan een dag</span>
      </label>
    </div>

    {wholeDay && multipleDays && <div className={`mt-3 flex space-x-4`}>
      <Input type={"date"} label={"Start datum"} value={startDate} onChange={() => {
      }} readonly={true}/>
      <Input type={"date"} label={"Eind datum"} value={endDate} onChange={setEndDate}/>
    </div>}
    {!wholeDay && !multipleDays && <div className={`mt-3 flex space-x-4`}>
      <Input type={"time"} label={"Starttijd"} value={startDate} onChange={(newDate) => {
        const newDateWithDate = moment(newDate).set({
          year: startDate.getFullYear(),
          month: startDate.getMonth(),
          date: startDate.getDate(),
        }).toDate()
        setStartDate(newDateWithDate)
      }}/>
      <Input type={"time"} label={"Eindtijd"} value={endDate} onChange={(newDate) => {
        const newDateWithDate = moment(newDate).set({
          year: endDate.getFullYear(),
          month: endDate.getMonth(),
          date: endDate.getDate(),
        }).toDate()
        setEndDate(newDateWithDate)
      }}/>
    </div>}

    {!wholeDay && multipleDays && <>
      <div className={`mt-3 flex space-x-4`}>
        <Input type={"date"} label={"Start datum"} value={startDate} onChange={() => {
        }} readonly={true}/>
        <Input type={"time"} label={"Starttijd"} value={startDate} onChange={(newDate) => {
          const newDateWithDate = moment(newDate).set({
            year: startDate.getFullYear(),
            month: startDate.getMonth(),
            date: startDate.getDate(),
          }).toDate()
          setStartDate(newDateWithDate)
        }}/>
      </div>
      <div className={`mt-3 flex space-x-4`}>
        <Input type={"date"} label={"Eind datum"} value={endDate} onChange={(newDate) => {
          const newDateWithDate = moment(newDate).set({
            hours: endDate.getHours(),
            minutes: endDate.getMinutes(),
          }).toDate()
          setEndDate(newDateWithDate)
        }}/>
        <Input type={"time"} label={"Eindtijd"} value={endDate} onChange={(newDate) => {
          const newDateWithDate = moment(newDate).set({
            year: endDate.getFullYear(),
            month: endDate.getMonth(),
            date: endDate.getDate(),
          }).toDate()
          setEndDate(newDateWithDate)
        }}/>
      </div>
    </>}


    {props.reason === 'holiday' && <label
      className={`flex items-center px-4 mt-4 rounded h-12 mb-3 border cursor-pointer ${paidVacation ? "border-blue-800 bg-blue-50" : "border-slate-200"}`}>
      <input type="checkbox" checked={paidVacation} onChange={() => setPaidVacation(x => !x)}/>
      <span className={"ml-2 font-medium text-sm"}>Doorbetaald verlof</span>
    </label>}
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter text={props.reason === "ill" ? "Ziek melden" : "Verlof registreren"} icon={faSave}
                 onSubmit={() => submit()}/>
  </div>
}

const TemporaryChanges: FC<{ date: Date, employee: Employee }> = props => {
  const {teams, cancelPlannedChange} = useApi()
  const temporaryChanges = useMemo(() => {
    return teams
      .map(t => t.members)
      .flat()
      .filter(m => m.isTemporary)
      .filter(m => m.employeeId === props.employee.id && ((m.startedAt <= props.date && (m.endedAt === null || m.endedAt >= props.date)) || moment(m.startedAt).isSame(props.date, 'day') || (m.endedAt !== null && moment(m.endedAt).isSame(props.date, 'day'))))
  }, [teams, props.date, props.employee])

  const cancel = async (teamId: string, teamMembershipId: string) => {
    await cancelPlannedChange(teamId, teamMembershipId)
  }

  const formatDate = (date: Date): string => {
    const m = moment(date)
    if (m.isSame(props.date, 'day')) return moment(date).format('HH:mm')
    if (m.isSame(props.date, 'week')) return moment(date).format('ddd HH:mm')
    return moment(date).format('DD-MM-YYYY HH:mm')
  }

  if (temporaryChanges.length === 0) return <></>

  return <>
    <h2 className={"text-blue-900 text-sm font-medium"}>Tijdelijke wijzigingen</h2>
    <div className={"border border-slate-200 rounded"}>
      {temporaryChanges.map((tc, i) => {
        const team = teams.find(t => t.id === tc.teamId)
        const start = formatDate(tc.startedAt)
        const end = tc.endedAt ? formatDate(tc.endedAt) : '-'
        return <div key={i} className={`flex items-center justify-between px-4 py-3 ${i !== 0 && 'border-t border-slate-200'}`}>
          <div className={"flex items-center"}>
            <div className={"flex items-center justify-center rounded-full w-8 h-8 bg-blue-100 text-blue-900 mr-4"}>
              {tc.reason === null ? <FontAwesomeIcon icon={faShuffle} /> : (tc.reason === 'ill' ? <FontAwesomeIcon icon={faThermometer} /> : <FontAwesomeIcon icon={faHouse} />)}
            </div>
            <div className={"flex flex-col"}>
              <div className={"font-medium text-sm text-slate-900"}>{team?.id === 'absence' ? 'Afwezig' : team?.name}</div>
              <div
                className={"text-xs text-slate-600"}>{start} - {end}</div>
            </div>
          </div>
          <div className={"flex items-center"}>
            <Button type={'danger'} size={'xs'} text={'Annuleren'} icon={faCancel} onClick={() => cancel(tc.teamId, tc.id)} />
          </div>
        </div>;
      })}
    </div>
    <h2 className={"text-blue-900 text-sm font-medium"}>Acties</h2>
  </>
}