import {
  faBoxOpen,
  faCalendar, faCalendarMinus, faCode,
  faDolly, faFileExcel, faPaintRoller,
  faPeopleGroup,
  faPerson,
  faReceipt,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import config from "./config";

export const navigation: {link: string, icon: IconDefinition, text: string}[] = [
  {
    link: '/',
    text: 'Planning',
    icon: faCalendar,
  },
  {
    link: '/bestellingen',
    text: 'Bestellingen',
    icon: faDolly,
  },
  {
    link: '/workorders',
    text: 'Werkbonnen',
    icon: faReceipt,
  },
  {
    link: '/personeel',
    text: 'Personeel',
    icon: faPerson,
  },
  {
    link: '/teams',
    text: 'Teams',
    icon: faPeopleGroup,
  },
  {
    link: '/holidays',
    text: 'Vrije dagen',
    icon: faCalendarMinus,
  },
  {
    link: '/reports',
    text: 'Raportage',
    icon: faFileExcel,
  },
  {
    link: '/leveranciers',
    text: 'Leveranciers',
    icon: faBoxOpen,
  },
  {
    link: '/kleuren',
    text: 'Kleuren',
    icon: faPaintRoller,
  },
  ...(config.devMode ? [
    {
      link: '/instellingen',
      text: 'DevSettings',
      icon: faCode,
    },
  ] : []),
]