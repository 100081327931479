import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {ModalFooter, useModal, useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {Project, QuantityType, Task, Team, Workorder} from "../api/dto";
import {useApiCall, ValidationError} from "../api/api";
import {useApi} from "../api/APIContext";
import {DataTable} from "@traas/lib/src/components/data/DataTable";
import moment from "moment";
import {usePlanner} from "../planning/PlannerContext";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faCircleQuestion, faFileExport, faImage} from "@fortawesome/free-solid-svg-icons";
import {TaskContextMenu} from "../planning/components/TaskContextMenu";
import {useThemes} from "../planning/util/theming";
import {useTaskTypes} from "../planning/hooks/useTaskTypes";
import {WorkorderDetailsModal} from "./WorkorderDetailsModal";
import { Button } from "@traas/lib/src/components/form/Button";

export const ProjectDetailsModal: FC<{project: Project, goToDay: (day: Date) => void, focusTask: (task: Task) => void}> = (props) => {

  return <div>
    {/* Information*/}
    <ProjectOverview project={props.project} />
    {/* Tabs */}
    <ProjectTabs project={props.project} focusTask={props.focusTask} goToDay={props.goToDay} />
  </div>
}
const ProjectOverview = ({project}: {project: Project}) => {
  return <div className={'grid grid-cols-1 lg:grid-cols-2 gap-4'}>
    <div>
      <div className={'text-lg font-bold mb-2'}>Projectgegevens</div>
      <div className={'border border-slate-200 px-4 py-3 rounded-lg grid grid-cols-3'}>
        <div className={'font-medium text-slate-800'}>Nr.:</div>
        <div className={'col-span-2'}>{project.projectNumber}</div>
        <div className={'font-medium text-slate-800'}>Omschrijving:</div>
        <div className={'col-span-2'}>{project.description}</div>
      </div>
    </div>
    <div>
      <div className={'text-lg font-bold mb-2'}>Aflever informatie</div>
      <div className={'border border-slate-200 px-4 py-3 rounded-lg grid grid-cols-3'}>
        <div className={'font-medium text-slate-800'}>Naam:</div>
        <div className={'col-span-2'}>{project.projectNumber}</div>
        <div className={'font-medium text-slate-800'}>E-mail:</div>
        <div className={'col-span-2'}>{project.deliveryDetails?.email}</div>
        <div className={'font-medium text-slate-800'}>Telefoon:</div>
        <div className={'col-span-2'}>{project.deliveryDetails?.phone}</div>
        <div className={'font-medium text-slate-800'}>Adres:</div>
        <div className={'col-span-2'}>
          <div>{project.deliveryDetails?.address}</div>
          <div>{project.deliveryDetails?.zipcode} {project.deliveryDetails?.city}</div>
        </div>
      </div>
    </div>
  </div>
}
const ProjectTabs = ({project, goToDay, focusTask}: {project: Project, goToDay: (day: Date) => void, focusTask: (day: Task) => void}) => {
  const {workorders, tasks} = useApi()
  const projectTasks = tasks.filter(t => t.projectId === project.id)
  const projectWorkorders = workorders.filter(w => w.projectNumber === project.projectNumber)
  const tabs = [
    {name: `Taken (${projectTasks.length})`, content: <ProjectTasksTab project={project} tasks={projectTasks} focusTask={focusTask} />},
    {name: `Werkbonnen (${projectWorkorders.length})`, content: <ProjectWorkordersTab project={project} workorders={projectWorkorders} goToDay={goToDay} tasks={projectTasks} />},
  ]
  const [tab, setTab] = React.useState<number>(0)
  return <div>
    <div className={'-mx-4 border-b-[3px] border-slate-300 flex px-4'}>
      {tabs.map((t, i) => <button className={`rounded-t h-12 font-medium text-sm uppercase px-4 border-b-[3px] ${i === tab ? 'text-blue-800 bg-blue-50 border-blue-800' : 'text-slate-800 border-slate-300 hover:text-blue-800'} -mb-[3px]`} key={i} onClick={() => setTab(i)}>{t.name}</button>)}
    </div>
    <div className={'h-[50vh] overflow-y-scroll -mx-4'}>
      <div className={'my-4 mx-4'}>
        {tabs[tab].content}
      </div>
    </div>
  </div>
}

const ProjectTasksTab = (props: {project: Project, tasks: Task[], focusTask: (day: Task) => void}) => {
  const {teams, quantityTypes} = useApi()
  const navigate = useNavigate()
  const {close} = useModalControls()
  const sortedTasks = props.tasks.sort((a, b) => {
    if (a.startAt === null && b.startAt === null) return 0;
    if (a.startAt === null) return -1;
    if (b.startAt === null) return 1;
    return b.startAt.getTime() - a.startAt.getTime();
  })
  return <div className={''}>
    <DataTable keyProperty={'id'} data={sortedTasks} compact={true} placeholder={'Geen taken'} columns={[
      {header: 'Hoeveelheid', property: 'quantityAmount', transform: (_, t) => {
          if (t.quantityAmount !== null && t.quantityType !== null) {
            const type = quantityTypes.find(qt => qt.id === t.quantityType)
            if (type?.hideArea) {
              return type.name
            }
            return `${t.quantityAmount}m² ${type?.name ?? t.quantityType}`;
          } else {
            return '';
          }
        }},
      {header: 'Omschrijving', property: 'description'},
      {header: 'Gepland', property: 'startAt', transform: (v: Date|null) => <span className={v && moment(v).isBefore(new Date) ? 'text-slate-500' : 'font-medium'}>{v !== null ? moment(v).format('DD-MM-YYYY') : '-'}</span>},
      {header: 'Team', property: 'teamId', transform: (v: string|null) => teams.find(t => t.id === v)?.name ?? ''},
    ]} onClickRow={(row) => {
      if (row.startAt !== null) {
        props.focusTask(row)
        navigate('/')
        close()
      }
    }} />
  </div>
}

const ProjectWorkordersTab = (props: {project: Project, workorders: Workorder[], goToDay: (day: Date) => void, tasks: Task[]}) => {
  const {teams, quantityTypes} = useApi()
  const {downloadBatchWorkorderPdf} = useApiCall()
  const sortedWorkorders = props.workorders.sort((a, b) => {
    if (a.startAt === undefined && b.startAt === undefined) return 0;
    if (a.startAt === undefined) return -1;
    if (b.startAt === undefined) return 1;
    return b.startAt.getTime() - a.startAt.getTime();
  })
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const downloadReport = async () => {
    const blob = await downloadBatchWorkorderPdf(checkedItems)
    const file = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = file
    a.download = `${props.project.id}.pdf`
    a.click()
    URL.revokeObjectURL(file)
  }

  return <>
    {checkedItems.length > 0 && <div className={"mb-2"}>
      <Button type={'primary'} size={'md'}
              text={checkedItems.length >= 2 ? `Exporteer werkbonnen (${checkedItems.length})` : `Exporteer werkbon`}
              icon={faFileExport}
              onClick={downloadReport}/>
    </div>}

    <div className={"border-2 border-slate-200 rounded"}>
      <table className={'w-full'}>
        <tbody>
        <tr className={'text-left border-b-2 border-slate-100'}>
          <th></th>
          <th className={'pl-2'}>Hoeveelheid</th>
          <th>Omschrijving</th>
          <th>Status</th>
          <th>Gepland</th>
          <th>Team</th>
          <th></th>
        </tr>
        {sortedWorkorders.map((workorder, index) => (
          <CustomWorkOrderTableRow
            key={workorder.id}
            workorder={workorder} isFirst={index === 0}
            isLast={sortedWorkorders.length - 1 === index}
            quantityTypes={quantityTypes}
            project={props.project}
            teams={teams}
            tasks={props.tasks}
            state={checkedItems}
            stateSetter={setCheckedItems}
          />
        ))}
        </tbody>
      </table>
    </div>
  </>
}

const CustomWorkOrderTableRow: FC<{
  workorder: Workorder,
  isFirst: boolean,
  isLast: boolean,
  quantityTypes: QuantityType[],
  project: Project,
  teams: Team[],
  tasks: Task[],
  state: string[],
  stateSetter: Dispatch<SetStateAction<string[]>>
}> = (props) => {
  const detailsModal = useModal({title: "Werkbon", size: 'xl', body: <WorkorderDetailsModal order={props.workorder!}/>})
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const task = props.tasks.find((task) => task.id == props.workorder.workorderPlanningItemsTaskId)
  const {getTheme} = useThemes()
  const {getTaskName, getTaskColor} = useTaskTypes()
  const theme = getTheme(getTaskColor(task!) ?? undefined, task!.isInvoiced)
  let imgCount = 0;
  let questionCount = 0;
  props.workorder.feedback?.map((feedback) => {
    if (feedback.answerType === 'image') {
      imgCount++;
    } else {
      questionCount++;
    }
  })
  const updateSelection = async (key: string) => {
    if (props.state.includes(key)) {
      props.stateSetter(props.state.filter(x => x != key))
    }
    if (!props.state.includes(key)) {
      props.stateSetter([...props.state, key])
    }
  }
  return <>
    <tr className={`${props.isLast ? '' : 'border-b-2'} border-slate-100 hover:bg-slate-50 cursor-pointer`} onClick={() => setShowDetails(!showDetails)}>
      <td className={'h-10 pl-2'}><input className={'hover:cursor-pointer'} type={"checkbox"} onClick={(e) => {
        updateSelection(props.workorder.workorderId!)
        e.stopPropagation()
      }} /></td>
      <td className={'pl-2'}>
        {task ? getTaskName(task) : '?'}
      </td>
      <td>{props.workorder.description}</td>
      <td>
        {props.workorder.isFinished ? (
          <div className="flex">
            <div className="px-2 h-5 text-xs rounded-full text-green-700 bg-green-100 font-medium uppercase flex items-center justify-start">
              <div className="h-2 w-2 rounded-full bg-green-600 mr-2"></div>
              <span>Afgerond</span>
            </div>
          </div>
        ) : (
          <div className="flex">
            <div className="px-2 h-5 text-xs rounded-full text-amber-700 bg-amber-100 font-medium uppercase flex items-center justify-start">
              <div className="h-2 w-2 rounded-full bg-amber-600 mr-2"></div>
              <span>Verstuurd</span>
            </div>
          </div>
        )}
      </td>
      <td>
        <span className={props.workorder.startAt && moment(props.workorder.startAt).isBefore(new Date) ? 'text-slate-500' : 'font-medium'}>
          {props.workorder.startAt !== null ? moment(props.workorder.startAt).format('DD-MM-YYYY') : '-'}
        </span>
      </td>
      <td>{props.teams.find((team) => team.id === props.workorder.teamId)?.name}</td>
      <td><FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} /></td>
    </tr>

  {showDetails && <tr className={'border-b-2 border-slate-100 bg-slate-50'}>
    <td colSpan={6} className={"py-2 px-3"}>
    <div className={'grid grid-cols-12 gap-4'}>
      <div className={'col-span-4'}>
        {task && <div className={`${theme.bg} rounded text-white text-sm hover:cursor-pointer`} onClick={() => detailsModal.open()}>
          <TaskContextMenu task={task} onLeftMouseDown={() => {
          }} className={'flex space-x-3'}>
            <>
              <button className={`w-8 -mr-2 flex flex-col items-center justify-center hover:bg-[#0002]`}>
              <span
                className={"h-3 w-4 flex items-center justify-center text-[11px] text-[#fffd]"}>{moment(task.startAt!).year()}</span>
                <span
                  className={"h-3 flex items-center justify-center text-[12px] font-medium text-white"}><span
                  className={"mr-[2px] text-[#fffa]"}>w</span>{moment(task.startAt!).week()}</span>
              </button>
            </>
            <div className={`flex-1 text-left py-2 px-3`}>
              {task.isInvoiced && <span className={"font-bold text-white"}>&euro;&nbsp;</span>}{getTaskName(task)}
              {props.project && <p
                className={`${theme.lightText} text-xs overflow-ellipsis -mx-1 px-1 ${theme.bg} rounded`}>{task.isInvoiced &&
                <span
                  className={`font-bold ${theme.text}`}>&euro;&nbsp;</span>}#{props.project.projectNumber}, {props.project.deliveryDetails.city} - {props.project.description}</p>}
            </div>
          </TaskContextMenu>
        </div>}

      </div>

      <div className={'col-span-2'}>
        <div className={'h-16 border border-slate-200 px-3 py-2 rounded flex flex-col justify-center'}>
          <div className={'flex justify-center items-center'}>
            <FontAwesomeIcon icon={faImage} className={'text-slate-500 mr-2'}/>
            <span className={'font-medium text-lg text-right'}>{imgCount}x</span>
          </div>
          <h1 className={'font-medium text-center text-sm'}>Afbeeldingen</h1>
        </div>
      </div>

      <div className={'col-span-2'}>
        <div className={'h-16 border border-slate-200 px-3 py-2 rounded flex flex-col justify-center'}>
          <div className={'flex justify-center items-center'}>
            <FontAwesomeIcon icon={faCircleQuestion} className={'text-slate-500 mr-2'}/>
            <span className={'font-medium text-lg text-right'}>{questionCount}x</span>
          </div>
          <h1 className={'font-medium text-center text-sm'}>Ingevuld</h1>
        </div>
      </div>

      <div className={'col-span-4 border border-slate-200 px-2 py-1 text-sm rounded'}>
        <h1 className={'font-medium mb-1'}>Opmerking</h1>
        <pre className={'text-sm font-sans text-slate-500 px-2 py-1 rounded bg-slate-100 leading-tight whitespace-pre-wrap'}>{props.workorder.workorderRemark}</pre>
      </div>
    </div>
  </td>
  </tr>}
    </>
}