import React, {Dispatch, FC, SetStateAction, useEffect, useState} from "react";
import {useApi} from "../api/APIContext";
import {useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {Task} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faSave,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {dateToString} from "../planning/util/datetime";
import {Button} from "@traas/lib/src/components/form/Button";
import {MultiSelectCalendar} from "@traas/lib/src/components/form/MultiSelectCalendar";
import {useApiCall} from "../api/api";
import {Autocomplete} from "@traas/lib/src/components/form/Autocomplete";


export const CopyTaskToTeamModal: FC<{task: Task, setDay: Dispatch<SetStateAction<Date>>}> = (props) => {
  const {planTask, addTask, teams} = useApi()
  const { close } = useModalControls()
  const [selected, setSelected] = useState<Date[]>([])
  const [errors, setErrors] = React.useState<{ [date: string]: string }>({})
  const [isSaving, setIsSaving] = useState(false)

  const currentDate = props.task.startAt!
  const currentTeams = teams.filter((t) => t.startedAt.getTime() < currentDate.getTime() && (t.endedAt === null || t.endedAt.getTime() > currentDate.getTime()))
  const refactoredTeams: [string,string][] = currentTeams.map((t) => [t.id, t.name]);
  const [selectedTeam, setSelectedTeam] = useState('')

  const confirm = async () => {
    setIsSaving(true)
    const add = await addTask(props.task.projectId!, props.task.description, props.task.quantityType!, props.task.quantityAmount!);
    const failed = await planTask(add, selectedTeam, props.task.startAt!, props.task.durationMinutes!)
  }

  return <div className={"space-y-8"}>
    <div className={'flex justify-center min-h-20'}>
      <Autocomplete label={''} options={refactoredTeams} value={selectedTeam ?? '-'}
                    onChange={(t) => setSelectedTeam(t)}/>
    </div>
    <div className={"flex-col justify-center space-x-8"}>
      <div className={""}>
        <p className={"flex justify-center text-center text-slate-600 text-sm p-2"}>
          Selecteer ploegen om de afspraak naar te kopiëren. <br/>
          De afspraak zal dan ingepland worden op deze dag voor die ploegen, <br/>
          op de zelfde tijd als de originele afspraak.
        </p>
      </div>

    </div>
    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'primary'} disabled={isSaving} size={'md'} text={isSaving ? 'Opslaan...' : 'Aanmaken'} icon={faSave}
              onClick={confirm}/>
      <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
    </footer>
  </div>
}


