import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ALL_TEAMS_CATEGORY, usePlanner} from "../PlannerContext";
import moment from "moment";
import {dateToString} from "../util/datetime";
import {Employee, Note, Team, TeamAvailability, TeamDayAvailability} from "../../api/dto";
import {useApi} from "../../api/APIContext";
import {TinyEmployeeAvatar} from "../../personel/components/EmployeeAvatar";
import {PlanCanvas} from "./PlanCanvas";
import {MoveEmployeeModal} from "../../modals/MoveEmployeeModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faChevronDown, faChevronUp, faCircleXmark,
  faGift,
  faInfo,
  faNoteSticky, faPaperPlane,
  faPencil,
  faPlus,
  faSquareMinus, faSquarePlus,
  faThermometer, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {AddTempTeamModal} from "../../modals/AddTempTeamModal";
import {TaskList} from "./ProjectCard";
import {isMousePositionInElement} from "../util/positioning";
import {SendWorkordersModal} from "../../modals/SendWorkordersModal";
import {usePersistentState} from "@traas/lib/src/util/usePersistentState";
import {HoverHint} from "@traas/lib/src/components/content/HoverHint";
import {Button, IconButton} from "@traas/lib/src/components/form/Button";
import {useModal} from "@traas/lib/src/components/layout/ModalProvider";

export const MONDAY = 1, TUESDAY = 2, WEDNESDAY = 3, THURSDAY = 4, FRIDAY = 5, SATURDAY = 6, SUNDAY = 7

export type EnrichedTeamDayAvailability = TeamDayAvailability & { team: Team, employees: Employee[] }
export const WeekdayPlanner: FC<{day: number, teamAvailabilities?: TeamDayAvailability[], teamAbsences?: TeamDayAvailability[]}> = (props) => {
  const {day: plannerDay, scaleConfig, getPlannedTasksForDay, category, bufferSize, setBufferSize} = usePlanner()
  const {teams, employees, holidays} = useApi()
  const planDate = useMemo(() => {
    const m = moment(plannerDay)
    return m.day(props.day).toDate()
  }, [plannerDay])

  const enrichedTeamAvailabilities = useMemo(() => {
    const t = props.teamAvailabilities?.map(tda => {
      return {
        ...tda,
        team: teams.find(t => t.id === tda.teamId),
        employees: employees.filter(e => tda.employeeIds.includes(e.id))
      }
    }).filter(x => x.team !== undefined && x.teamId !== 'absence') as EnrichedTeamDayAvailability[]|undefined
    return t?.sort((a, b) => a.team.order - b.team.order) ?? []
  }, [props.teamAvailabilities, teams, employees]);

  const enrichedAbsenceTeam = useMemo(() => {
    const absenceTeam = props.teamAvailabilities?.find(t => t.teamId === "absence")
    return {
      ...absenceTeam,
      team: {id: "absence", name: "Afwezig", order: 100},
      employees: employees.filter(e => absenceTeam?.employeeIds.includes(e.id))
    } as EnrichedTeamDayAvailability
  }, [props.teamAvailabilities, employees])

  const holiday = useMemo(() => {
    return holidays.find(h => moment(planDate).isSame(h.date, "date"))
  }, [holidays, planDate])
  const [planHolidayAnyway, setPlanHolidayAnyway] = useState(false)

  const tasksToday = getPlannedTasksForDay(planDate)
  const hasPlannedTasksForToday = tasksToday.length > 0
  if (holiday && !planHolidayAnyway && !hasPlannedTasksForToday) {
    return <div className={"mb-4"}>
      <h2 className={"text-sm font-medium"}>{dateToString(planDate)}</h2>
      <div className={"text-xs mt-2 py-4 px-3 bg-slate-100 rounded"}>
        <h2 className={"text-sm font-medium"}>{holiday.name}</h2>
        <p className={"text-slate-600 mb-2"}>Dit is een algemene vrije dag. In principe wordt hier niet op gepland. Wil je toch een tijdelijk team inplannen? Dan kun je een uitzondering maken</p>
        <Button type={'primary'} size={'xs'} text={'Toch plannen op deze dag'} onClick={() => setPlanHolidayAnyway(true)} />
      </div>
    </div>
  }
  const filteredEnrichedTeamAvailabilities = enrichedTeamAvailabilities
    .filter(tda => tda.employees.length > 0 || tda.team.isTemporary)
  // If there are tasks planned for a team, but that team is not returned in the availability, add it to the list
  const teamIdsAvailable = filteredEnrichedTeamAvailabilities.map(tda => tda.teamId)
  const teamsToday = Object.keys(tasksToday.reduce((acc, t) => (t.teamId ? {...acc, [t.teamId]: true} : acc), {} as {[teamId: string]: boolean}))
    .filter(teamId => !teamIdsAvailable.includes(teamId))
    .map(teamId => teams.find(t => t.id === teamId)).filter(x => !!x) as Team[]
  const teamAvailability = teamsToday.map(t => ({teamId: t.id, team: t, employeeIds: [], employees: [], employeeMemberships: []}))
    filteredEnrichedTeamAvailabilities.push(...teamAvailability)
  return <div className={"flex items-stretch"}>
    <div className={"mb-4"}>
      <div className={"flex justify-between"}>
        <h2 className={"text-sm font-medium flex items-center whitespace-nowrap"}>{dateToString(planDate)} {holiday && <HoverHint hint={'Dit is een vrije dag. Je kunt bij uitzondering plannen op deze dag, maar in principe is iedereen vrij.'}><span className={"text-xs text-white bg-blue-800 px-2 py-1 rounded-full ml-2"}><FontAwesomeIcon icon={faGift} className={"mr-1"} />{holiday.name}</span></HoverHint>}</h2>
        <div className={"flex"} style={{width: `${scaleConfig.slotSize * 48}px`, marginRight: `-${scaleConfig.slotSize*4}px`}}>
          {[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map(h => {
            return <div key={h} className={'flex-1 flex justify-start'}>
              <div className={"w-16 -ml-8 text-xs text-slate-400 text-center"}>{h}:00</div>
            </div>
          })}
        </div>
      </div>
      <div>
        {filteredEnrichedTeamAvailabilities && props.teamAbsences
          ? filteredEnrichedTeamAvailabilities.filter(tda => category === undefined || category === ALL_TEAMS_CATEGORY || tda.team.category === category).map((tda, i) => <WeekdayTeamPlanner key={i} date={planDate} tda={tda} tdas={filteredEnrichedTeamAvailabilities} absence={enrichedAbsenceTeam} />)
          : <div className="h-16 w-full rounded-lg bg-slate-100 animate-pulse"></div>}
        {filteredEnrichedTeamAvailabilities?.length === 0 && <div className={"text-xs mt-2 py-4 px-3 bg-slate-100 text-gray-700"}>Geen teams ingepland</div>}
      </div>
      <WeekdayAbsenceDrawer tdas={filteredEnrichedTeamAvailabilities} absenceTeam={enrichedAbsenceTeam} date={planDate} />
      <div className={"flex items-center space-x-4"}>
        <AddTemporaryTeamButton date={planDate} />
        <PlanWorkordersButton teamAvailabilities={filteredEnrichedTeamAvailabilities} date={planDate} />
      </div>
    </div>
    <div className={"ml-8 flex flex-col items-stretch pr-32 pb-10"}>
      <div className={`flex items-center text-sm ${bufferSize}`}>
        <h2 className={"flex-1 font-medium whitespace-nowrap"}>Overschot: {dateToString(planDate)}</h2>
        <button className={"hover:bg-slate-200 hover:text-blue-800 text-slate-600 cursor-pointer rounded ml-1"} onClick={() => setBufferSize(bufferSize === 'w-96' ? 'w-80' : 'w-72')}>
          <FontAwesomeIcon icon={faSquareMinus} className={"m-1"}/>
        </button>
        <button className={"hover:bg-slate-200 hover:text-blue-800 text-slate-600 cursor-pointer rounded ml-1"} onClick={() => setBufferSize(bufferSize === 'w-72' ? 'w-80' : 'w-96')}>
          <FontAwesomeIcon icon={faSquarePlus} className={"m-1"}/>
        </button>
      </div>
      <BufferCanvas date={planDate}/>
    </div>
  </div>
}

const WeekdayTeamPlanner: FC<{ tda: EnrichedTeamDayAvailability, tdas: EnrichedTeamDayAvailability[], date: Date, absence: EnrichedTeamDayAvailability}> = (props) => {
  const {updateTeamsOrder} = useApi()
  const [hasDismissed, setHasDismissed] = usePersistentState<boolean>(`${moment(props.date).format('YYYY-MM-DD')}-${props.tda.teamId}-warning-dismissed`, false)
  function updateTeamOrder(team: EnrichedTeamDayAvailability, allTeams: EnrichedTeamDayAvailability[], direction: string): EnrichedTeamDayAvailability[] {
    const teamAIndex = allTeams.findIndex(t => t.teamId === team.teamId);

    let teamBIndex: number;
    if (direction === 'up') {
      teamBIndex = teamAIndex - 1 ;
    } else {
      teamBIndex = teamAIndex + 1;
    }

    const newOrder = allTeams.map(t => {
      if (t.teamId === allTeams[teamAIndex].teamId) {
        return allTeams[teamBIndex] ?? t
      }
      if (t.teamId === allTeams[teamBIndex].teamId) {
        return allTeams[teamAIndex] ?? t
      }
      return t
    })

    updateTeamsOrder(newOrder.map(t => t.teamId));
    return newOrder;
  }

  const first = Math.min(...props.tdas.map((f) => f.team.order))
  const last = Math.max(...props.tdas.map((l) => l.team.order));

  // Does this team have any absent members today?
  const absenceIds = props.absence.employeeMemberships
    .filter(m => m.isTemporary) // Planned absence is not noteworthy
    .map(m => m.employeeId)
  const regularTeamMemberIds = props.tda.team.members
    .filter(m => !m.isTemporary)
    .map(m => m.employeeId)
  const areMembersAbsent = regularTeamMemberIds.some(id => absenceIds.includes(id))
  const shouldShowAbsenceWarning = areMembersAbsent && !hasDismissed

  return <>
    <div className={"h-12 flex items-stretch mt-1"}>
      {props.tda.team.isTemporary ?
        <div className={"py-2 -ml-6 w-6 flex flex-col items-center justify-around"}>
          <IconButton type={'secondary'} disabled={props.tda.team.order === first} size={'xs'} icon={faChevronUp} onClick={() => {
            updateTeamOrder(props.tda, props.tdas, 'up')
          }} />
          <IconButton type={'secondary'} disabled={props.tda.team.order === last} size={'xs'} icon={faChevronDown} onClick={() => {
            updateTeamOrder(props.tda, props.tdas, 'down')
          }} />
        </div>
        : <></>}
      <div className={"flex flex-row"}>
        <div className={`border border-slate-200 ${shouldShowAbsenceWarning ? 'bg-amber-200' : 'bg-white'} rounded-l w-[250px] h-full px-2 flex flex-col justify-center`}>
          <h3 className={"text-[10px] h-4 tracking-wide uppercase font-medium "}>{props.tda.team.name}</h3>
          <div className={"flex items-center"}>
            {props.tda.employees.map((e, i, s) => <InteractiveEmployeeAvatar key={i} date={props.date} tda={props.tda}
                                                                             teams={props.tdas} e={e} s={s}/>)}
          </div>
        </div>
        {shouldShowAbsenceWarning &&
          <>
            <div className={"flex items-center -ml-8 px-2 text-amber-600 hover:text-gray-500 group"}>
              <div className={"group-hover:hidden"}><FontAwesomeIcon icon={faTriangleExclamation}/></div>
              <div className={"hidden group-hover:block cursor-pointer"}><FontAwesomeIcon icon={faCircleXmark} onClick={() => setHasDismissed(true)}/></div>
              <span className={"absolute ml-8 bg-slate-700 text-white py-1 px-2 rounded hidden group-hover:block max-w-8 z-10"}>LET OP! Op deze dag heeft dit team minder capaciteit i.v.m. verlof of ziekte</span>
            </div>
          </>
        }
      </div>
      <PlanCanvas team={props.tda.team} date={props.date}/>
    </div>
  </>
}

const WeekdayAbsenceDrawer: FC<{
  tdas?: EnrichedTeamDayAvailability[],
  absenceTeam?: EnrichedTeamDayAvailability,
  date: Date
}> = (props) => {
  const absentEmployees = props.absenceTeam?.employees || []
  if (props.absenceTeam === undefined || absentEmployees.length === 0) return <></>
  const unknownReasonEmployees = absentEmployees.filter(e => !props.absenceTeam?.employeeMemberships.find(em => em.employeeId === e.id)?.reason)
  const sickReasonEmployees = absentEmployees.filter(e => props.absenceTeam?.employeeMemberships.find(em => em.employeeId === e.id)?.reason === 'ill')
  const plannedReasonEmployees = absentEmployees.filter(e => props.absenceTeam?.employeeMemberships.find(em => em.employeeId === e.id)?.reason === 'holiday')
  return <div className={"border border-slate-200 bg-white rounded h-12 mt-1 px-2 flex items-stretch"}>
    {(unknownReasonEmployees.length > 0 || ((sickReasonEmployees.length + plannedReasonEmployees.length) === 0)) && <div className={"mr-4"}>
      <h3 className={"text-[10px] h-4 tracking-wide uppercase font-medium "}>{props.absenceTeam.team.name}</h3>
      <div className={"flex"}>
        {unknownReasonEmployees.map((e, i, s) => <InteractiveEmployeeAvatar key={i} date={props.date}
                                                                            tda={props.absenceTeam!} teams={props.tdas!}
                                                                            e={e} s={s}/>)}
      </div>
    </div>}
    {sickReasonEmployees.length > 0 && <div className={"mr-4"}>
      <h3 className={"text-[10px] h-4 tracking-wide uppercase font-medium "}>Ziek</h3>
      <div className={"flex"}>
        {sickReasonEmployees.map((e, i, s) => <InteractiveEmployeeAvatar key={i} date={props.date}
                                                                         tda={props.absenceTeam!} teams={props.tdas!}
                                                                         e={e} s={s}/>)}
      </div>
    </div>}
    {plannedReasonEmployees.length > 0 && <div className={"mr-4"}>
      <h3 className={"text-[10px] h-4 tracking-wide uppercase font-medium "}>Verlof</h3>
      <div className={"flex"}>
        {plannedReasonEmployees.map((e, i, s) => <InteractiveEmployeeAvatar key={i} date={props.date}
                                                                            tda={props.absenceTeam!} teams={props.tdas!}
                                                                            e={e} s={s}/>)}
      </div>
    </div>}
  </div>
}

export const InteractiveEmployeeAvatar: FC<{
  e: Employee,
  s: Employee[],
  tda: EnrichedTeamDayAvailability,
  teams: EnrichedTeamDayAvailability[],
  date: Date
}> = ({date, e, s, tda, teams}) => {
  const moveEmployeeModal = useModal({
    title: 'Personeel herindelen',
    body: <MoveEmployeeModal date={date} employee={e} teams={teams} currentTeam={tda} />})
  // TODO: Hier hebben we in principe de data beschikbaar om een dubbele entry in de planning te voorkomen.
  const tdaMembership = tda.employeeMemberships.find(em => em.employeeId === e.id)
  const isTemporary = tdaMembership?.isTemporary ?? false
  let message = isTemporary ? (
    'Tijdelijk ingepland'
    + (tdaMembership && tdaMembership.startedAt.getHours() > 5 ? ` vanaf ${tdaMembership.startedAt.getHours()}:${String(tdaMembership.startedAt.getMinutes()).padStart(2, '0')}` : '')
    + (tdaMembership && tdaMembership.endedAt && tdaMembership.endedAt.getHours() < 18 ? ` tot ${tdaMembership.endedAt.getHours()}:${String(tdaMembership.endedAt.getMinutes()).padStart(2, '0')}` : '')
  ) : undefined
  let icon = faInfo
  if (tdaMembership?.reason === 'ill') {
    message = 'Ziek'
    icon = faThermometer
  }
  return <div className={s.length > 3 ? '-mr-2' : 'mr-1'}><TinyEmployeeAvatar onClick={() => moveEmployeeModal.open()} employee={e} hint={message ? {text: message, icon} : undefined} /></div>
}

const AddTemporaryTeamButton: FC<{date: Date}> = ({date}) => {
  const addTempTeam = useModal({title: 'Tijdelijk team aanmaken', body: <AddTempTeamModal day={date} />})
  return <button className={"flex items-center text-xs font-medium text-blue-900 mt-1 p-1 -mx-1 -mb-1 hover:bg-blue-50 rounded-full"} onClick={() => addTempTeam.open()}>
    <div className={"text-[7px] text-white bg-blue-900 h-[12px] w-[12px] rounded-full flex items-center justify-center mr-1"}><FontAwesomeIcon icon={faPlus} /></div>
    Tijdelijk team aanmaken
  </button>
}

const BufferCanvas: FC<{date: Date}> = ({date}) => {
  const {tasks} = useApi()
  const bufferTasks = useMemo(() => {
    return tasks.filter(t => t.teamId === null && t.startAt && moment(t.startAt).isSame(date, 'date'))
  }, [tasks, date])
  const {mousePos, area, setArea, mode} = usePlanner()
  const ref = useRef<HTMLDivElement>(null)
  const location = `buffer-${moment(date).toISOString()}`
  const inElement = useMemo(() => {
    if (mousePos && ref.current) {
      return isMousePositionInElement(mousePos, ref.current)
    } else {
      return false
    }
  }, [ref, mousePos])
  useEffect(() => {
    if (mousePos && ref.current) {
      if (inElement &&  area !== location) {
        setArea(location)
      } else if (! inElement && area === location) {
        setArea("world")
      }
    }
  }, [ref, mousePos, area, inElement])
  const shouldHighlight = useMemo(() => {
    return mode === "float" && inElement
  }, [mode, inElement])
  return <div className={`flex-1 border ${shouldHighlight ? 'border-blue-200 bg-blue-50' : 'border-slate-200 bg-white'} rounded`} ref={ref}>
    <div className={"h-full w-full relative"}>
      <div className={"absolute inset-0 px-2 overflow-y-scroll"}>
        <NoteEditor date={date} />
        <TaskList dragMode={"always"} tasks={bufferTasks} taskFilter={"all"} showProjects={true} />
      </div>
    </div>
  </div>
}

const NoteEditor: FC<{date: Date}> = props => {
  const {notes, employees, editNote} = useApi()
  const [mode, setMode] = useState<"view"|"edit">("view")
  const note = useMemo(() => notes.find(n => moment(n.date).isSame(props.date, "date")), [notes, props.date])
  const [viewText, setViewText] = useState(note?.text ?? '')
  const [text, setText] = useState(note?.text ?? '')
  const [editStart, setEditStart] = useState<Date|undefined>(undefined)
  const edit = () => {
    setText(note?.text ?? '')
    setEditStart(note?.updatedAt)
    setMode("edit")
  }
  const save = async () => {
    await editNote(props.date, text)
    setViewText(text)
    setMode("view")
  }
  useEffect(() => {
    setViewText(note?.text ?? '')
  }, [note]);
  const hasChanges = editStart !== undefined && note !== undefined && editStart !== note?.updatedAt
  return <div className={"border border-slate-200 rounded p-2 my-2"}>
    <div className={"flex items-center justify-between"}>
      <span className={"flex items-center text-xs font-bold text-blue-900"}>
        <FontAwesomeIcon icon={faNoteSticky} className={"text-blue-900 text-xs mr-2"} />
        Notitie
      </span>
      <span className={"text-xs"}>{lastEditedText(employees, note)}</span>
    </div>
    {mode === "view" && <button className={"text-xs text-slate-600 mt-2 p-2 rounded flex w-full hover:bg-slate-50"} onClick={() => edit()}>
      <pre className={'flex-1 text-left font-sans whitespace-pre-wrap'}>{viewText}</pre>
      <FontAwesomeIcon icon={faPencil} className={"text-slate-600 text-xs mr-2"} />
    </button>}
    {mode === "edit" && <div>
      {hasChanges && <div className={"text-xs text-amber-700 mt-2 font-medium"}>Let op! Iemand anders heeft wijzigingen opgeslagen terwijl jij aan het typen was.</div>}
      <textarea className={"outline-none bg-slate-100 rounded mt-2 mb-1 p-2 text-sm w-full"} value={text} onChange={(e) => setText(e.target.value)} />
      <div className={"flex space-x-2"}>
        <Button type={'primary'} size={'xs'} text={'Opslaan'} onClick={() => save()} />
        <Button type={'secondary'} size={'xs'} text={'Annuleren'} onClick={() => setMode("view")} />
      </div>
    </div>}
  </div>
}

function lastEditedText(employees: Employee[], note?: Note): string {
  if (! note) {
    return ''
  }
  const lastEditedBy = getNoteEmployeeName(employees, note)
  const minuteDiff = moment().diff(note.updatedAt, 'minutes')
  const minutesAgo = minuteDiffToText(minuteDiff)
  return `${minutesAgo}, ${lastEditedBy}`
}

function getNoteEmployeeName(employees: Employee[], note: Note): string {
  return employees.find(e => e.id === note.lastModifiedByUserId)?.firstName ?? 'Onbekend'
}

function minuteDiffToText(diff: number): string {
  if (diff <= 1) {
    return "zojuist"
  }
  if (diff <= 60) {
    return `${diff} min. geleden`
  }
  if (diff <= 60*24) {
    return `${Math.floor(diff/60)} uur geleden`
  }
  return `${Math.floor(diff/(60*24))} dagen geleden`
}
const PlanWorkordersButton: FC<{teamAvailabilities: EnrichedTeamDayAvailability[], date: Date}> = props => {
  const {getBlocksInCanvas} = usePlanner()
  const planTasks = props.teamAvailabilities.map((tda) => {
    const tasksInCanvas = getBlocksInCanvas(props.date, tda.team.id)
    return tasksInCanvas.filter(t => !t.details.isSent)
  }).flat()
  const sendModal = useModal({title: 'Werkbonnen versturen', body: <SendWorkordersModal tasks={planTasks.map(b => b.details)} />})
  if (planTasks.length === 0) {
    return <></>
  }
  return <button
    className={"flex items-center text-xs font-medium text-blue-900 mt-1 p-1 -mx-1 -mb-1 hover:bg-blue-50 rounded-full"}
    onClick={() => sendModal.open()}>
    <div
      className={"text-[7px] text-white bg-blue-900 h-[12px] w-[12px] rounded-full flex items-center justify-center mr-1"}>
      <FontAwesomeIcon icon={faPaperPlane}/></div>
    Werkbonnen versturen
  </button>
}