import React, {FC, useEffect, useRef, useState} from "react";
import {Employee, EmployeeWorkDays} from "../../api/dto";
import {useApi} from "../../api/APIContext";
import {getWeek} from "@traas/lib/src/util/getWeekNumber";
import {HoverHint} from "@traas/lib/src/components/content/HoverHint";
import {Button} from "@traas/lib/src/components/form/Button";
import {faCheck, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {usePermissions} from "../../auth/permissions";

export const RoosterEditor: FC<{employee: Employee}> = (props) => {
    const permission = usePermissions()
    const {getWorkDays, setWorkDays} = useApi()
    const [selectedEmployee, setSelectedEmployee] = useState<Employee|null>(null)
    const [rooster, setRooster] = useState<EmployeeWorkDays&{dirty:boolean}|null>(null)
    useEffect(() => {
        if (selectedEmployee?.id !== props.employee.id) {
            setSelectedEmployee(props.employee)
            setRooster(null)
            getWorkDays(props.employee).then(r => setRooster({...r, dirty: false}))
        }
    }, [props.employee.id, selectedEmployee]);
    const save = async (): Promise<void> => {
        if (rooster?.dirty) {
            await setWorkDays(props.employee, rooster)
            setRooster({...rooster, dirty: false})
        }
    }
    const weeknr = getWeek(new Date())
    return <div>
        <table>
            <thead>
            <tr>
                <th></th>
                <th className={"text-center text-xs font-bold text-slate-600"}>MA</th>
                <th className={"text-center text-xs font-bold text-slate-600"}>DI</th>
                <th className={"text-center text-xs font-bold text-slate-600"}>WO</th>
                <th className={"text-center text-xs font-bold text-slate-600"}>DO</th>
                <th className={"text-center text-xs font-bold text-slate-600"}>VR</th>
                <th className={"text-center text-xs font-bold text-slate-600"}>ZA</th>
                <th className={"text-center text-xs font-bold text-slate-600 pr-2"}>ZO</th>
            </tr>
            </thead>
          {permission.canEditEmployeesWorkdays &&
            <tbody>
            <tr className={(weeknr % 2) === 0 ? 'bg-slate-100' : ''}>
              <td className={"px-2 rounded-l"}>Even weken {(weeknr % 2) === 0 && <HoverHint hint={'Het is nu een even week'}><span className={'inline-block h-2 w-2 bg-blue-600 rounded-full mx-2'}></span></HoverHint>}</td>
              <td className={""}><RoosterEditorCell value={rooster?.evenMonday??0} status={rooster === null ? 'loading' : (rooster.evenMonday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenMonday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.evenTuesday??0} status={rooster === null ? 'loading' : (rooster.evenTuesday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenTuesday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.evenWednesday??0} status={rooster === null ? 'loading' : (rooster.evenWednesday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenWednesday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.evenThursday??0} status={rooster === null ? 'loading' : (rooster.evenThursday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenThursday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.evenFriday??0} status={rooster === null ? 'loading' : (rooster.evenFriday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenFriday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.evenSaturday??0} status={rooster === null ? 'loading' : (rooster.evenSaturday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenSaturday: newState} : null)}/></td>
              <td className={"pr-2 rounded-r"}><RoosterEditorCell value={rooster?.evenSunday??0} status={rooster === null ? 'loading' : (rooster.evenSunday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, evenSunday: newState} : null)}/></td>
            </tr>
            <tr className={(weeknr % 2) === 1 ? 'bg-blue-100' : ''}>
              <td className={"px-2 rounded-l"}>Oneven weken {(weeknr % 2) === 1 && <HoverHint hint={'Het is nu een oneven week'}><span className={'inline-block h-2 w-2 bg-blue-600 rounded-full mx-2'}></span></HoverHint>}</td>
              <td className={""}><RoosterEditorCell value={rooster?.oddMonday??0} status={rooster === null ? 'loading' : (rooster.oddMonday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddMonday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.oddTuesday??0} status={rooster === null ? 'loading' : (rooster.oddTuesday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddTuesday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.oddWednesday??0} status={rooster === null ? 'loading' : (rooster.oddWednesday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddWednesday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.oddThursday??0} status={rooster === null ? 'loading' : (rooster.oddThursday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddThursday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.oddFriday??0} status={rooster === null ? 'loading' : (rooster.oddFriday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddFriday: newState} : null)}/></td>
              <td className={""}><RoosterEditorCell value={rooster?.oddSaturday??0} status={rooster === null ? 'loading' : (rooster.oddSaturday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddSaturday: newState} : null)}/></td>
              <td className={"pr-2 rounded-r"}><RoosterEditorCell value={rooster?.oddSunday??0} status={rooster === null ? 'loading' : (rooster.oddSunday ? 'enabled' : 'disabled')} onClick={newState => setRooster(r => r ? {...r, dirty: true, oddSunday: newState} : null)}/></td>
            </tr>
            </tbody>
          }

          {!permission.canEditEmployeesWorkdays &&
            <tbody>
            <tr className={(weeknr % 2) === 0 ? 'bg-slate-100' : ''}>
              <td className={"px-2 rounded-l"}>Even weken {(weeknr % 2) === 0 && <HoverHint hint={'Het is nu een even week'}><span className={'inline-block h-2 w-2 bg-blue-600 rounded-full mx-2'}></span></HoverHint>}</td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenMonday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenTuesday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenWednesday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenThursday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenFriday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenSaturday ? 'enabled' : 'disabled')} /></td>
              <td className={"pr-2 rounded-r"}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.evenSunday ? 'enabled' : 'disabled')} /></td>
            </tr>
            <tr className={(weeknr % 2) === 1 ? 'bg-blue-100' : ''}>
              <td className={"px-2 rounded-l"}>Oneven weken {(weeknr % 2) === 1 && <HoverHint hint={'Het is nu een oneven week'}><span className={'inline-block h-2 w-2 bg-blue-600 rounded-full mx-2'}></span></HoverHint>}</td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddMonday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddTuesday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddWednesday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddThursday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddFriday ? 'enabled' : 'disabled')} /></td>
              <td className={""}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddSaturday ? 'enabled' : 'disabled')} /></td>
              <td className={"pr-2 rounded-r"}><RoosterCellViewer state={rooster === null ? 'loading' : (rooster.oddSunday ? 'enabled' : 'disabled')} /></td>
            </tr>
            </tbody>
          }

        </table>
      {rooster?.dirty && <div className={'mt-4'}>
        <Button type={'primary'} size={'sm'} icon={faSave} text={'Wijzigingen opslaan'} onClick={save}/>
      </div>}
    </div>
}

const RoosterEditorCell: FC<{
  status: "enabled" | "disabled" | "loading",
  value: number,
  onClick: (newState: number) => void
}> = props => {
  const click = () => {
    if (props.status !== "loading") {
      props.onClick(props.value)
    }
  }
  let style = ''
  switch (props.status) {
    case "enabled":
      style = 'bg-green-600 hover:bg-green-500 text-white';
      break;
    case "disabled":
      style = 'bg-red-600 hover:bg-red-500 text-[#fffc]';
      break;
    case "loading":
      style = 'bg-slate-200 animate-pulse';
      break;
  }

  const formatValue = (value: number): string => {
    const hourString = (value / 60).toFixed(2)
    if (hourString.charAt(hourString.length - 1) === '0') {
      return hourString.substring(0, hourString.length - 1)
    }
    return hourString
  }

  const [input, setInput] = useState(formatValue(props.value))
  const [hasFocus, setHasFocus] = useState(false)

  useEffect(() => {
    if (!hasFocus) {
      setInput(formatValue(props.value))
    }
  }, [hasFocus, props.value]);

  if (props.status === "loading") {
    return <div className={'m-1 h-8 w-8 bg-slate-200 animate-pulse rounded'}>

    </div>
  }

  return <input onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} type={'text'} className={`m-1 h-8 w-8 ${style} flex items-center justify-center rounded text-center font-medium`} value={input} onChange={(e) => {
    const filtered = e.target.value.replace(/[^0-9,.]/g, '')
    const parsed = parseFloat(filtered.replace(',', '.'))
    if (!isNaN(parsed)) {
      props.onClick(Math.round(parsed * 60))
    }
    setInput(filtered)
  }} onClick={click} />
}

const RoosterCellViewer: FC<{ state: "enabled" | "disabled" | "loading" }> = props => {
  let style = ''
  switch (props.state) {
    case "enabled":
      style = 'bg-green-600 text-white';
      break;
    case "disabled":
      style = 'bg-red-600 text-white';
      break;
    case "loading":
      style = 'bg-slate-200 animate-pulse';
      break;
  }
  return <div className={`m-1 h-8 w-8 ${style} flex items-center justify-center rounded`}>
    {props.state !== "loading" &&
      <FontAwesomeIcon icon={props.state === "enabled" ? faCheck : faTimes} className={"h-3 w-3"}/>}
  </div>
}