import {useApi} from "../../api/APIContext";
import {FC, useMemo} from "react";
import {Employee, Team} from "../../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {dateToString} from "../../planning/util/datetime";
import {faCancel, faShuffle} from "@fortawesome/free-solid-svg-icons";
import {CancelPlannedChangeModal} from "../../modals/CancelPlannedChangeModal";
import {useModal} from "@traas/lib/src/components/layout/ModalProvider";

export const UpcomingChanges = () => {
  const {teams, employees} = useApi()
  const now = new Date();
  const changes = useMemo(() => {
    const changes: { id: string, date: Date, end: Date | null, employee: Employee, team: Team, temporary: boolean }[] = []
    const employeeMap = new Map<string, Employee>()
    employees.forEach(e => employeeMap.set(e.id, e))
    teams.filter(t => t.id !== 'absence').forEach(t => {
      t.members.forEach(m => {
        if (m.startedAt.getTime() > now.getTime()) {
          const e = employeeMap.get(m.employeeId)
          if (!e) {
            return
          }
          changes.push({
            id: m.id,
            date: m.startedAt,
            end: m.endedAt,
            employee: e,
            team: t,
            temporary: t.isTemporary,
          })
        }
      })
    })
    return changes.sort((a, b) => a.date.getTime() - b.date.getTime())
  }, [teams, employees, now])


  if (changes.length === 0) {
    return <div
      className={"border border-slate-100 rounded py-8 flex items-center justify-center text-xs text-slate-700"}>
      <p>Geen aankomende wijzigingen</p>
    </div>
  }

  return <div className={"border border-slate-200 rounded"}>
    {changes.map((c, i) => <div key={i}
                                className={`${i > 0 && 'border-t'} border-slate-200 px-3 text-sm text-slate-700 py-2`}>
      <UpcomingChange {...c} />
    </div>)}
  </div>
}

export const UpcomingChange: FC<{ id: string, date: Date, employee: Employee, team: Team, temporary: boolean }> = (props) => {
  const cancelPlannedChangeModal = useModal({title: "Weet je het zeker?",
    body: <CancelPlannedChangeModal team={props.team} employee={props.employee} date={props.date}
                                    membershipId={props.id}/>
  })
  return <>
    <div className={'flex items-center'}>
      <FontAwesomeIcon icon={faClock} className={"w-4 mr-2"}/>
      <div>
        <h3 className={"font-medium"}>{dateToString(props.date)}</h3>
      </div>
      {props.temporary && <>
        <span className={"px-2 py-1 rounded-full bg-amber-200 text-amber-800 text-sm font-medium"}>
          Tijdelijke wijziging
        </span>
      </>}
    </div>
    <div className={'flex items-center'}>
      <FontAwesomeIcon icon={faShuffle} className={"w-4 mr-2"}/>
      <div>
        <h3>
          <strong>{props.employee.firstName} {props.employee.lastName}</strong> naar <strong>{props.team.name}</strong>
        </h3>
      </div>
    </div>

    <button className={"text-red-600 font-medium py-1 -mb-1 -mx-2 px-2 rounded hover:bg-red-100"}
            onClick={() => cancelPlannedChangeModal.open()}>
      <FontAwesomeIcon icon={faCancel} className={"w-4 mr-2"}/>
      Annuleren
    </button>
  </>
}