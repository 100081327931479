import React, {FC, useEffect, useState} from "react";
import {FloatingCard} from "./components/FloatingCard";
import {PlanningContainer} from "./components/PlanningContainer";
import {FRIDAY, MONDAY, THURSDAY, TUESDAY, WEDNESDAY, SATURDAY, SUNDAY, WeekdayPlanner} from "./components/WeekdayPlanner";
import {useTeamAvailability} from "./hooks/useTeamAvailability";
import {Button} from "@traas/lib/src/components/form/Button";

export const WeekOverview: FC = () => {
  const teamAvailability = useTeamAvailability()
  const [canPlanWeekend, setCanPlanWeekend] = useState(false)
  useEffect(() => {
    const weekendTeams = (teamAvailability?.saturday.filter(t => t.teamId !== 'absence').length ?? 0) + (teamAvailability?.sunday.filter(t => t.teamId !== 'absence').length ?? 0)
    setCanPlanWeekend(weekendTeams > 0)
  }, [teamAvailability]);

  return <PlanningContainer>
    <div className={"flex-1 flex flex-col"}>
      <WeekdayPlanner day={MONDAY} teamAvailabilities={teamAvailability?.monday} teamAbsences={teamAvailability?.monday.filter(t => t.teamId === 'absence')}/>
      <WeekdayPlanner day={TUESDAY} teamAvailabilities={teamAvailability?.tuesday} teamAbsences={teamAvailability?.tuesday.filter(t => t.teamId === 'absence')}/>
      <WeekdayPlanner day={WEDNESDAY} teamAvailabilities={teamAvailability?.wednesday} teamAbsences={teamAvailability?.wednesday.filter(t => t.teamId === 'absence')}/>
      <WeekdayPlanner day={THURSDAY} teamAvailabilities={teamAvailability?.thursday} teamAbsences={teamAvailability?.thursday.filter(t => t.teamId === 'absence')}/>
      <WeekdayPlanner day={FRIDAY} teamAvailabilities={teamAvailability?.friday} teamAbsences={teamAvailability?.friday.filter(t => t.teamId === 'absence')}/>
      { canPlanWeekend ? <>
        <WeekdayPlanner day={SATURDAY} teamAvailabilities={teamAvailability?.saturday} teamAbsences={teamAvailability?.saturday.filter(t => t.teamId === 'absence')}/>
        <WeekdayPlanner day={SUNDAY} teamAvailabilities={teamAvailability?.sunday} teamAbsences={teamAvailability?.sunday.filter(t => t.teamId === 'absence')}/>
      </> : <>
        <div className={"text-xs mt-2 py-4 px-3 bg-slate-100 rounded"}>
          <h2 className={"text-sm font-medium"}>Weekend vrij</h2>
          <p className={"text-slate-600 mb-2"}>Over het algemeen wordt er in de weekenden geen werk gepland. Bij uitzondering kan er toch een tijdelijk team ingepland worden.</p>
          <Button type={'primary'} size={'xs'} text={'Toch plannen dit weekend'} onClick={() => setCanPlanWeekend(true)} />
        </div>
      </>}
    </div>
    {/* Floating planning card */}
    <FloatingCard />
  </PlanningContainer>
}





