import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import { Input } from "@traas/lib/src/components/form/Input";
import {ValidationError} from "../api/api";


export const AddTeamModal: FC<{}> = () => {
  const {addTeam} = useApi()
  const [name, setName] = React.useState("")
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await addTeam({name})
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <Input label={'Team naam'} value={name} focus={true} onChange={setName} type={'text'} />
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faPlus} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
