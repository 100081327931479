import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Employee} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Input} from "@traas/lib/src/components/form/Input";


export const DisableEmployeeModal: FC<{employee: Employee}> = (props) => {
  const {updateEmployee} = useApi()
  const [archiveDate, setArchiveDate] = useState(new Date())
  const confirm = async () => {
    await updateEmployee({...props.employee, enabled: false}, archiveDate)
  }

  return <>
    <p className={"mb-2"}>
      <strong>Let op!</strong> Je staat op het punt om <strong>{props.employee.firstName} {props.employee.lastName}</strong> op non-acief te zetten.
      Dit betekent dat hij/zij niet meer in de planning kan worden gezet. Je kunt deze actie later ongedaan maken.
    </p>
    <Input type={'date'} label={"Non-actief vanaf"} value={archiveDate} onChange={(d) => setArchiveDate(d)} />
    <ModalFooter icon={fa.faTimes} text={"Naar non-actief"} onSubmit={confirm} />
  </>
}
