import React, {FC, useEffect, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useNavigate} from "react-router-dom";
import {Employee, Workorder} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter, useModalControls} from "@traas/lib/src/components/layout/ModalProvider";
import {Button} from "@traas/lib/src/components/form/Button";
import {useApiCall} from "../api/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";


export const EmployeeWorkordersModal: FC<{employee: Employee}> = (props) => {
  const {close} = useModalControls()
  const {getEmployeeWorkorders} = useApiCall()
  const [workorders, setWorkorders] = useState<Workorder[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    setLoading(true)
    getEmployeeWorkorders(props.employee).then(setWorkorders).finally(() => setLoading(false))
  }, []);
  const orderedWorkorders = workorders.sort((a,b) => {
    if (!a.startAt && !!b.startAt) {
      return -1;
    }
    if (!b.startAt && !!a.startAt) {
      return 1;
    }
    return a.startAt! > b.startAt! ? 1 : -1;
  }).filter((wo) => {
    if (wo.isFinished === false) {
      return true;
    }
    if (!wo.startAt) {
      return false;
    }
    if (moment().diff(moment(wo.startAt), 'hours') <= 24) {
      return true;
    }
    if (moment().diff(moment(wo.startAt), 'hours') >= 72) {
      return false;
    }
    // If the workorder is on friday, include it when it is currently monday.
    if (moment(wo.startAt).weekday() === 5) {
      return moment().weekday() === 1;
    }
    const woIsToday = moment(wo.startAt).weekday() === moment().weekday()
    const woIsYesterday = moment(wo.startAt).weekday() === (moment().weekday() - 1)
    return woIsToday || woIsYesterday;
  })
  return <>
    {loading ? <>
      <div className={"h-32 flex items-center justify-center"}>
        <FontAwesomeIcon icon={fa.faCircleNotch} spin={true} className={'text-blue-800 text-xl'} />
      </div>
    </> : <>
      {orderedWorkorders.map((wo, i) => <div key={i} className={"border border-slate-100 rounded p-3 my-2 flex flex-col items-stretch"}>
        <div className={'flex items-center justify-between text-xs'}>
          <span className={'font-medium'}>#{wo.projectNumber}</span>
          <div>
            <FontAwesomeIcon icon={fa.faLocationPin} className={'text-blue-800 mr-2'} />
            <span className={'text-blue-800'}>{wo.projectDeliveryCity}</span>
          </div>
        </div>
        <h2 className={'font-medium mb-2'}>{wo.projectDescription ?? 'Overig project'}</h2>
        {wo.quantityAmount !== null && wo.quantityTypeName !== null && <>
          <div className={"text-sm"}>
            {wo.quantityAmount}m² {wo.quantityTypeName}
          </div>
        </>}
        <div className={'text-sm'}>{wo.description}</div>
        <div className={'flex justify-between'}>
          <div className={'text-sm'}>
            {wo.startAt !== null ? moment(wo.startAt).format('DD-MM-YYYY') : 'Geen datum'}
          </div>
          {wo.isFinished && <div className={"text-sm px-2 ml-2 rounded-full bg-blue-800 text-white"}>Afgerond</div>}
        </div>
      </div>)}
    </>}
    <div className={"flex flex-col items-stretch py-4"}>
      <Button type={'secondary'} size={'md'} text={'Sluiten'} onClick={close} />
    </div>
  </>
}
