import React, {FC} from "react";
import {ModalFooter} from "@traas/lib/src/components/layout/ModalProvider";
import {Workorder} from "../api/dto";
import {ValidationError} from "../api/api";
import {useApi} from "../api/APIContext";

export const UnarchiveModal: FC<{order: Workorder}> = (props) => {
  const {unarchiveWorkOrder} = useApi()
  const [errors, setErrors] = React.useState<string[]>([])
  const unArchive = async () => {
    await unarchiveWorkOrder(props.order.workorderId!).catch(e => {
      if (e instanceof ValidationError) {
        setErrors(Object.values(e.errors).flat())
      }
      throw e
    })
  }
  return <div>
    <p>Weet je zeker dat je deze werkbon wilt dearchiveren?</p>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter text={'Dearchiveren'} onSubmit={unArchive} />
  </div>
}