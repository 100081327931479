import React, {FC} from "react";
import {usePlanner} from "./PlannerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarDay, faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
export const WeekPicker: FC = () => {
  const {day, setDay} = usePlanner()
  const now = moment()
  const value = moment(day)

  return <div className={"flex items-center"}>
    <div className={"flex items-center relative gap-1"}>
      <button className={"hidden 2xl:block h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDay(value.clone().subtract(5, 'week').toDate())}><FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronLeft} className={"-ml-1"} /></button>
      <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDay(value.clone().subtract(1, 'week').toDate())}><FontAwesomeIcon icon={faChevronLeft} /></button>
      <button className={"hidden 2xl:block w-8 py-1 rounded text-sm text-blue-300 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().subtract(4, 'week').toDate())}>{value.clone().subtract(4, 'week').week()}</button>
      <button className={"hidden xl:block w-8 py-1 rounded text-sm text-blue-400 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().subtract(3, 'week').toDate())}>{value.clone().subtract(3, 'week').week()}</button>
      <button className={"hidden xl:block w-8 py-1 rounded text-sm text-blue-500 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().subtract(2, 'week').toDate())}>{value.clone().subtract(2, 'week').week()}</button>
      <button className={"w-8 py-1 rounded text-sm text-blue-600 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().subtract(1, 'week').toDate())}>{value.clone().subtract(1, 'week').week()}</button>
      <div className={"w-10 py-1 font-bold relative"}>
        <span className={"h-10 w-10 rounded-full flex items-center justify-center border-2 border-blue-900"}>{value.week()}</span>
        <div className={'absolute -bottom-1 left-1/2 -translate-x-1/2 font-medium text-xs h-4 bg-blue-900 rounded text-white px-1'}>{value.year()}</div>
      </div>
      <button className={"w-8 py-1 rounded text-sm text-blue-600 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().add(1, 'week').toDate())}>{value.clone().add(1, 'week').week()}</button>
      <button className={"hidden xl:block w-8 py-1 rounded text-sm text-blue-500 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().add(2, 'week').toDate())}>{value.clone().add(2, 'week').week()}</button>
      <button className={"hidden xl:block w-8 py-1 rounded text-sm text-blue-400 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().add(3, 'week').toDate())}>{value.clone().add(3, 'week').week()}</button>
      <button className={"hidden 2xl:block w-8 py-1 rounded text-sm text-blue-300 hover:bg-slate-200 font-medium"} onClick={() => setDay(value.clone().add(4, 'week').toDate())}>{value.clone().add(4, 'week').week()}</button>
      <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDay(value.clone().add(1, 'week').toDate())}><FontAwesomeIcon icon={faChevronRight} /></button>
      <button className={"hidden 2xl:block h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDay(value.clone().add(5, 'week').toDate())}><FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} className={"-ml-1"} /></button>
    </div>
    {(now.week() !== value.week() || now.year() !== value.year()) && (
      <button className={"text-sm font-medium text-blue-600 hover:bg-slate-200 px-4 py-1"} onClick={() => setDay(new Date())}>
        <FontAwesomeIcon icon={faCalendarDay} />
        <span className={"ml-2 hidden 2xl:inline"}>Naar deze week</span>
      </button>
    )}
  </div>
}
