import {FC} from "react";
import {ConfigInterface} from "../../config";

export const NavBarAppSwitcher: FC<{config: ConfigInterface}> = (props) => {
  const {planningUrl, crmUrl} = props.config
  const isPlanning = window.location.href.includes(props.config.planningUrl)
  const isCrm = window.location.href.includes(props.config.crmUrl)
  return <div className={'flex items-center'}>
    <a href={planningUrl} className={isPlanning ? 'text-sm font-medium px-2 py-1 rounded bg-white text-blue-800' : 'text-sm font-medium px-2 py-1 rounded'}>Planning & Werkbonnen</a>
    <a href={crmUrl} className={isCrm ? 'text-sm font-medium px-2 py-1 rounded bg-white text-blue-800' : 'text-sm font-medium px-2 py-1 rounded'}>Klanten & Projecten</a>
  </div>

}