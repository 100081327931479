export class Employee {
  constructor(
    public id: string,
    public username: string,
    public firstName: string,
    public lastName: string,
    public email: string|null,
    public role: "employee"|"office",
    public roles: [],
    public enabled: boolean,
    public metadata: EmployeeMetadata,
  ) {
  }

  static fromJson(json: any): Employee {
    return new Employee(
      json['id'],
      json['username'],
      json['firstName'],
      json['lastName'],
      json['email'],
      json['role'],
      json['roles'] ?? [],
      json['enabled'],
      json['metadata'] ? EmployeeMetadata.fromJson(json['metadata']) : new EmployeeMetadata(null, false, null, null, null),
    );
  }
}

export class EmployeeMetadata {
  constructor(
    public readonly archivedAt: Date|null,
    public readonly isBsnSet: boolean,
    public readonly color: string|null,
    public readonly contractStartDate: Date|null,
    public readonly contractEndDate: Date|null,
  ) {
  }

  static fromJson(json: any): EmployeeMetadata {
    return new EmployeeMetadata(
      json['archived_at'] ? new Date(json['archived_at']) : null,
      json['bsn_set'],
      json['color'],
      json['contract_start_date'] ? new Date(json['contract_start_date']) : null,
      json['contract_end_date'] ? new Date(json['contract_end_date']) : null,
    );
  }
}

export class EmployeeWorkDays {
  constructor(
    public evenMonday: number,
    public evenTuesday: number,
    public evenWednesday: number,
    public evenThursday: number,
    public evenFriday: number,
    public evenSaturday: number,
    public evenSunday: number,
    public oddMonday: number,
    public oddTuesday: number,
    public oddWednesday: number,
    public oddThursday: number,
    public oddFriday: number,
    public oddSaturday: number,
    public oddSunday: number,
    public startAt: Date,
    public endAt: Date|null,
  ) {
  }

  static fromJson(json: any): EmployeeWorkDays {
    return new EmployeeWorkDays(
      json['even_mon'],
      json['even_tue'],
      json['even_wed'],
      json['even_thu'],
      json['even_fri'],
      json['even_sat'],
      json['even_sun'],
      json['odd_mon'],
      json['odd_tue'],
      json['odd_wed'],
      json['odd_thu'],
      json['odd_fri'],
      json['odd_sat'],
      json['odd_sun'],
      new Date(json['start_at']),
      json['end_at'] ? new Date(json['end_at']) : null,
    )
  }
}
export class Project {
  constructor(
    public readonly id: string,
    public readonly projectNumber: string,
    public readonly description: string,
    public readonly status: string,
    public readonly deliveryDetails: ProjectDeliveryDetails,
  ) {
  }

  static fromJson(json: any): Project {
    return new Project(
      String(json['proteus_id']),
      json['project_number'],
      json['description'],
      json['status'],
      ProjectDeliveryDetails.fromJson(json),
    );
  }
}
export class ProjectDeliveryDetails {
  constructor(
    public readonly name: string,
    public readonly address: string,
    public readonly city: string,
    public readonly zipcode: string,
    public readonly email: string,
    public readonly phone: string,
    public readonly remark: string,
  ) {
  }

  static fromJson(json: any): ProjectDeliveryDetails {
    return new ProjectDeliveryDetails(
      json['delivery_name'],
      json['delivery_address'],
      json['delivery_city'],
      json['delivery_zipcode'],
      json['delivery_email'],
      json['delivery_phone'],
      json['delivery_remark'],
    );
  }
}
export const TASK_QUANTITY_TYPE_OTHER = 'Anders'
export class Task {
  constructor(
    public readonly id: string,
    public readonly projectId: string|null,
    public readonly teamId: string|null,
    public readonly startAt: Date|null,
    public readonly durationMinutes: number|null,
    public readonly description: string,
    public readonly color: string|null,
    public readonly suppliers: TaskSupplier[],
    public readonly suppliersStatus: string,
    public readonly quantityType: string|null,
    public readonly quantityAmount: number|null,
    public readonly quantityThickness: number|null,
    public readonly isInvoiced: boolean,
    public readonly isSent: boolean,
    public readonly workorderRemark: string|null,
    public readonly projectRemark: string|null,
    public readonly todos: {[text: string]: boolean},
  ) {}

  static fromJson(json: any): Task {
    return new Task(
      String(json['id']),
      String(json['project_id']),
      json['team_id'],
      json['start_at'] ? new Date(json['start_at']) : null,
      json['duration'] ? Number(json['duration']) : null,
      json['description'],
      json['color'],
      json['suppliers'].map((supplier: any) => TaskSupplier.fromJson(supplier)),
      json['suppliers_status'],
      json['quantity_type'],
      json['quantity_amount'],
      json['quantity_thickness'],
      json['is_invoiced'] ?? false,
      json['sent'] ?? false,
      json['workorder_remark'] ?? null,
      json['project_remark'] ?? null,
      json['todos'] ?? {},
    );
  }
}

export class TaskSupplier {
  constructor(
    public readonly id: string,
    public readonly supplierId: string,
    public readonly description: string|null,
    public readonly status: string,
    public readonly isComplete: boolean,
  ) {}

  static fromJson(json: any): TaskSupplier {
    return new TaskSupplier(
      String(json['id']),
      String(json['supplier_id']),
      json['description'],
      json['status'],
      json['is_complete'],
    );
  }
}

export class Supplier {
  constructor(
    public readonly id: string,
    public readonly name: string,
  ) {}

  static fromJson(json: any): Supplier {
    return new Supplier(
      String(json['id']),
      json['name'],
    );
  }
}

export class Holiday {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly date: Date,
    public readonly type: 'company_wide_vacation'|'national_holiday'|null,
  ) {
  }

  static fromJson(json: any): Holiday {
    return new Holiday(
      String(json['id']),
      json['name'],
      new Date(json['date']),
      json['type'],
    );
  }
}

export class TeamDayAvailability {
  constructor(public readonly teamId: string, public readonly employeeMemberships: TeamMembership[]) {
  }

  get employeeIds(): string[] {
    return this.employeeMemberships.map((membership) => membership.employeeId);
  }
}
export class TeamAvailability {
  constructor(
    public readonly weekStart: Date,
    public readonly weekEnd: Date,
    public readonly weekNumber: number,
    public readonly monday: TeamDayAvailability[],
    public readonly tuesday: TeamDayAvailability[],
    public readonly wednesday: TeamDayAvailability[],
    public readonly thursday: TeamDayAvailability[],
    public readonly friday: TeamDayAvailability[],
    public readonly saturday: TeamDayAvailability[],
    public readonly sunday: TeamDayAvailability[],
  ) {
  }

  static fromJson(json: any): TeamAvailability {
    return new TeamAvailability(
      new Date(json['startOfWeek']),
      new Date(json['endOfWeek']),
      json['week'],
      json['mon'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))),
      json['tue'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))),
      json['wed'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))),
      json['thu'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))),
      json['fri'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))),
      json['sat'] ? json['sat'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))) : [],
      json['sun'] ? json['sun'].map((teamDayAvailability: any) => new TeamDayAvailability(teamDayAvailability['teamId'], teamDayAvailability['employeeMemberships'].map(TeamMembership.fromJson))) : [],
    )
  }
}

export class PlanningAbsence {
  constructor(
    public readonly day: string,
    public readonly employeeId: string,
    public readonly reason: string,
  ) {
  }

  static fromJsonMap(json: {[day: string]: []|{[employeeId: string]: {employee_uuid: string, reason: string}}}): PlanningAbsence[] {
    return Object.entries(json).flatMap(([day, absences]) => {
      if (Array.isArray(absences)) {
        return [];
      }
      return Object.entries(absences).map(([employeeId, absence]) => {
        return new PlanningAbsence(day, employeeId, absence['reason']);
      });
    })
  }
}

export class Team {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly startedAt: Date,
    public readonly endedAt: Date|null,
    public readonly members: TeamMembership[],
    public readonly order: number,
    public readonly isTemporary: boolean,
    public readonly category: string,
  ) {}

  static fromJson(json: any): Team {
    return new Team(
      json['id'],
      json['name'],
      new Date(json['started_at']),
      json['ended_at'] ? new Date(json['ended_at']) : null,
      json['members'] ? json['members'].map(TeamMembership.fromJson) : [],
      json['order'] ?? 0,
      json['is_temporary'],
      json['category'],
    )
  }
}
export class TeamMembership {
  constructor(
    public readonly id: string,
    public readonly employeeId: string,
    public readonly teamId: string,
    public readonly startedAt: Date,
    public readonly endedAt: Date|null,
    public readonly reason: string|null,
    public readonly isTemporary: boolean = false,
  ) {}

  static fromJson(json: any): TeamMembership {
    return new TeamMembership(
      json['id'],
      json['employee_id'],
      json['team_id'],
      new Date(json['started_at']),
      json['ended_at'] ? new Date(json['ended_at']) : null,
      json['reason'] ?? null,
      json['is_temporary'] ?? false,
    )
  }
}

export class QuantityType {
  constructor(
    public readonly id: string,
    public readonly name: string,
    private readonly gradient: number|null,
    private readonly intercept: number|null,
    public readonly enabled: boolean,
    public readonly defaultColor: string|null,
    public readonly descriptionTemplate: string[],
    public readonly hideArea: boolean,
  ) {}

  static fromJson(json: any): QuantityType {
    return new QuantityType(
      json['id'],
      json['name'],
      json['gradient'],
      json['intercept'],
      json['enabled'],
      json['default_color'] ?? null,
      json['description_template'] ?? [],
      json['hide_area'],
    );
  }

  canPredict(): boolean {
    return this.gradient !== null && this.intercept !== null;
  }
  predict(quantity: number): number|null {
    if (this.gradient === null || this.intercept === null) {
      return null;
    }
    return this.gradient * quantity + this.intercept;
  }
}

export class Color {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly enabled: boolean,
    public readonly color: string,
    public readonly colorName: string,
  ) {}

  static fromJson(json: any): Color {
    return new Color(
      json['id'],
      json['name'],
      json['enabled'],
      json['color'],
      json['colorName'],
    );
  }
}

export class Note {
  constructor(
    public readonly id: string,
    public readonly date: Date,
    public readonly lastModifiedByUserId: string,
    public readonly text: string,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {}

  static fromJson(json: any): Note {
    return new Note(
      json['id'],
      new Date(json['date']),
      json['last_modified_by_user_id'],
      json['text'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class Order {
  constructor(
    public readonly id: string,
    public readonly date: Date,
    public readonly text: string,
    public readonly projectId: string,
    public readonly amount: number,
    public readonly quantity: number,
    public readonly quantityUnit: string,
    public readonly isDone: boolean,
    public readonly remark: string,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {}

  static fromJson(json: any): Order {
    return new Order(
      json['id'],
      new Date(json['date']),
      json['text'],
      String(json['project_id']),
      json['amount'] ?? 1,
      json['quantity'] ?? 1,
      json['quantity_unit'] ?? '',
      json['is_done'] ?? false,
      json['remark'] ?? '',
      new Date(json['created_at']),
      new Date(json['updated_at']),
    );
  }
}

export class Workorder {
  constructor(
  public readonly id: string,
  public readonly description: string,
  public readonly teamId?: string,
  public readonly startAt?: Date,
  public readonly archivedAt?: Date,
  public readonly quantityAmount?: number,
  public readonly quantityType?: string,
  public readonly quantityTypeName?: string,
  public readonly workorderRemark?: string,
  public readonly projectRemark?: string,
  public readonly projectNumber?: string,
  public readonly projectDescription?: string,
  public readonly projectNote?: string,
  public readonly projectLocation?: string,
  public readonly projectDeliveryName?: string,
  public readonly projectDeliveryAddress?: string,
  public readonly projectDeliveryCity?: string,
  public readonly projectDeliveryZipCode?: string,
  public readonly projectDeliveryPhone?: string,
  public readonly projectDeliveryEmail?: string,
  public readonly projectDeliveryRemark?: string,
  public readonly workorderId?: string,
  public readonly isFinished?: boolean,
  public readonly archived_at?: Date,
  public readonly workorderPlanningItemsTaskId?: string,
  public readonly workorderFeedbackType?: string,
  public readonly feedback?: WorkorderFeedback[],
  ) {
  }

  static fromJson(json: any): Workorder {
    return new Workorder(
      json['id'],
      json['description'],
      json['team_id'],
      json['start_at'] ? new Date(json['start_at']) : undefined,
      json['workorder']?.['archived_at'] ? new Date(json['workorder']?.['archived_at']) : undefined,
      json['quantity_amount'],
      json['quantity_type'],
      json['quantity_type_name'],
      json['workorder_remark'],
      json['project_remark'],
      json['project']?.['project_number'],
      json['project']?.['description'],
      json['project']?.['note'],
      json['project']?.['location'],
      json['project']?.['delivery_name'],
      json['project']?.['delivery_address'],
      json['project']?.['delivery_city'],
      json['project']?.['delivery_zipcode'],
      json['project']?.['delivery_phone'],
      json['project']?.['delivery_email'],
      json['project']?.['delivery_remark'],
      json['workorder']?.['id'],
      json['workorder']?.['is_finished'],
      json['archived_at'] ? new Date((json['archived_at'])) : undefined,
      json['workorder']?.['planning_items_task_id'],
      json['workorder']?.['workorder_feedback_type'],
      json['workorder']?.['feedback'] ? json['workorder']?.['feedback'].map((f: any) => WorkorderFeedback.fromJson(f)) : [],
    );

  }
}
export class WorkorderFeedback {
  constructor(
    public readonly id: string,
    public readonly employeeId: string,
    public readonly answerType: 'questions'|'image',
    public readonly answer: object,
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
  ) {
  }

  static fromJson(json: any): WorkorderFeedback {
    return new WorkorderFeedback(
      json['id'],
      json['employee_id'],
      json['answer_type'],
      json['answer'],
      new Date(json['created_at']),
      new Date(json['updated_at']),
    )
  }
}